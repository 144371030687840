<template>
  <div class="min-h-screen bg-gray-100">
    <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
    <div class="flex items-center justify-between bg-white shadow p-1">
      <div @click="goBack" class="cursor-pointer">
        <ChevronLeftIcon class="w-6 h-6 text-gray-700" />
      </div>
      <h2 class="main-title text-lg font-semibold text-center tracking-wide uppercase flex-1">My Purchases</h2>
      <SidebarToggle @toggle="toggleSidebar" />
    </div>
    <div v-if="loading" class="flex justify-center items-center mt-6">
      <LoadingSpinner />
    </div>
    <div v-else class="p-1">
      <div v-for="purchase in purchases" :key="purchase.id" class="billing-row" @click="openModal(purchase)">
        <div class="flex items-center justify-between">
          <div>
            <h3 class="text-lg font-bold" :class="{'text-green-600': purchase.status === 'Paid', 'text-red-600': purchase.status !== 'Paid'}">Status: {{ purchase.status }}</h3>
            <p class="text-gray-700"><strong>Date:</strong> {{ new Date(purchase.created_at).toLocaleString() }}</p>
            <p class="text-gray-700"><strong>Purchase No:</strong> {{ purchase.purchase_no }}</p>
            <p class="text-gray-700"><strong>Description:</strong> {{ purchase.description }}</p>
          </div>
          <img :src="purchase.image_url" alt="Merchandise Image" class="round-thumbnail">
        </div>
      </div>
      <ModalComponent :isOpen="isModalOpen" @close="isModalOpen = false">
        <template v-slot:header>
          <h3 class="bold-text">Receipt</h3>
        </template>
        <template v-slot:body>
          <div class="modal-content">
            <div class="flex items-center mb-1">
              <div>
                <p><strong>Order ID:</strong> {{ selectedPurchase.order_id }}</p>
                <p><strong>Purchase No:</strong> {{ selectedPurchase.purchase_no }}</p>
                <p><strong>Date:</strong> {{ new Date(selectedPurchase.created_at).toLocaleString() }}</p>
                <p><strong>Status:</strong> {{ selectedPurchase.status }}</p>
                <p><strong>Quantity:</strong> {{ selectedPurchase.quantity }}</p>
                <p><strong>Description:</strong> {{ selectedPurchase.description }}</p>
                <p><strong>Options:</strong> {{ parseOptions(selectedPurchase.options) }}</p>
              </div>
              <img :src="selectedPurchase.image_url" alt="Merchandise Image" class="round-thumbnail-modal ml-auto">
            </div>
            <div class="amounts-section">
              <p><strong>Delivery Charge:</strong> RM {{ selectedPurchase.deliverycharge }}</p>
              <p><strong>Delivery Address:</strong> {{ selectedPurchase.deliveryaddress }}</p>
              <p><strong>Contact:</strong> {{ selectedPurchase.contact }}</p>
              <p><strong>Total Amount:</strong> RM {{ calculateTotalAmount(selectedPurchase.price, selectedPurchase.discount, selectedPurchase.quantity) }}</p>
              <p><strong>Payment Gateway:</strong> {{ selectedPurchase.payment_gateway_no || 'null' }}</p>
              <p><strong>Discount:</strong> RM {{ selectedPurchase.discount * selectedPurchase.quantity }}</p>
              <p class="grand-total"><strong>Grand Total:</strong> RM {{ calculateGrandTotal(selectedPurchase.price, selectedPurchase.discount, selectedPurchase.quantity, selectedPurchase.deliverycharge) }}</p>
            </div>
            <div v-if="selectedPurchase.selfcollect && selectedPurchase.status === 'Paid'" class="qr-code">
              <img :src="generateQrCode(selectedPurchase.purchase_no)" alt="QR Code">
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <button @click="isModalOpen = false" class="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Close
          </button>
        </template>
      </ModalComponent>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import SidebarToggle from '../components/SidebarToggle.vue';
import SidebarComponent from '../components/SidebarComponent.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import ModalComponent from '../components/ModalComponent.vue';
import { supabase } from '../utils/supabase';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';
import '../assets/custom-alertify.css';

export default {
  name: 'MyPurchasesComponent',
  components: {
    ChevronLeftIcon,
    SidebarToggle,
    SidebarComponent,
    LoadingSpinner,
    ModalComponent
  },
  setup() {
    const purchases = ref([]);
    const loading = ref(true);
    const sidebarOpen = ref(false);
    const router = useRouter();
    const isModalOpen = ref(false);
    const selectedPurchase = ref({});
    const orderId = ref(null);

    const fetchPurchases = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          alertify.error('User not authenticated');
          router.push('/signin');
          return;
        }

        const { data, error } = await supabase
          .from('user_purchases')
          .select(`
            id,
            created_at,
            purchase_no,
            amount,
            status,
            order_id,
            payment_gateway_no,
            event_id,
            merchandise_id,
            quantity,
            deliverycharge,
            deliveryaddress,
            contact,
            discount,
            selfcollect,
            options
          `)
          .eq('user_id', user.id)
          .order('created_at', { ascending: false });

        if (error) throw error;

        for (let purchase of data) {
          const { data: event, error: eventError } = await supabase
            .from('events')
            .select('title')
            .eq('id', purchase.event_id)
            .single();

          if (eventError) throw eventError;

          const { data: merchandise, error: merchandiseError } = await supabase
            .from('event_merchandise')
            .select('image_url, description, price')
            .eq('id', purchase.merchandise_id)
            .single();

          if (merchandiseError) throw merchandiseError;

          purchase.event_title = event.title;
          purchase.image_url = merchandise.image_url;
          purchase.description = merchandise.description;
          purchase.price = merchandise.price;
        }

        purchases.value = data;
      } catch (error) {
        console.error('Error fetching purchases:', error);
        alertify.error('Failed to fetch purchases');
      } finally {
        loading.value = false;
      }
    };

    const openModal = (purchase) => {
      selectedPurchase.value = purchase;
      isModalOpen.value = true;
    };

    const fetchPurchaseByOrderId = async (orderId) => {
      try {
        const { data, error } = await supabase
          .from('user_purchases')
          .select(`
            id,
            created_at,
            purchase_no,
            amount,
            status,
            order_id,
            payment_gateway_no,
            event_id,
            merchandise_id,
            quantity,
            deliverycharge,
            deliveryaddress,
            contact,
            discount,
            selfcollect,
            options
          `)
          .eq('order_id', orderId);

        if (error) throw error;

        if (!data || data.length === 0) {
          console.error('No data returned from user_purchases insert');
          alertify.error('Failed to fetch purchase details');
          return;
        }

        const purchase = data[0];

        const { data: event, error: eventError } = await supabase
          .from('events')
          .select('title')
          .eq('id', purchase.event_id)
          .single();

        if (eventError) throw eventError;

        const { data: merchandise, error: merchandiseError } = await supabase
          .from('event_merchandise')
          .select('image_url, description, price')
          .eq('id', purchase.merchandise_id)
          .single();

        if (merchandiseError) throw merchandiseError;

        purchase.event_title = event.title;
        purchase.image_url = merchandise.image_url;
        purchase.description = merchandise.description;
        purchase.price = merchandise.price;

        selectedPurchase.value = purchase;
        isModalOpen.value = true;

        window.goto_param = '';
        window.order_id = '';
      } catch (error) {
        console.error('Error fetching purchase by order ID:', error);
        alertify.error('Failed to fetch purchase details');
      }
    };

    const calculateTotalAmount = (price, discount, quantity) => {
      const totalDiscount = discount * quantity;
      const totalAmount = price * quantity - totalDiscount;
      return totalAmount.toFixed(2);
    };

    const calculateGrandTotal = (price, discount, quantity, deliveryCharge) => {
      const totalAmount = calculateTotalAmount(price, discount, quantity);
      const grandTotal = parseFloat(totalAmount) + parseFloat(deliveryCharge);
      return grandTotal.toFixed(2);
    };

    const generateQrCode = (purchaseNo) => {
      return `https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=${purchaseNo}`;
    };

    const parseOptions = (options) => {
      console.log(options)
      if (!options) return "N/A";
      try {
        const parsed = options;
        return JSON.parse(parsed)
        // return Object.entries(parsed)
        //   .map(([key, value]) => `${key}: ${value}`)
        //   .join(", ");
      } catch (e) {
        console.error('Error parsing options:', e);
        return "N/A";
      }
    };

    const handleOrderUpdated = (event) => {
      const { order_id } = event.detail;
      fetchPurchaseByOrderId(order_id);
    };
    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };
    const goBack = () => {
      router.back();
    };

    onMounted(() => {
      fetchPurchases();

      // Check if goto_param is 'receipt' and listen for the 'OrderUpdated' event
      setTimeout(() => {
        if (window.goto_param === 'receipt') {
          window.addEventListener('OrderUpdated', handleOrderUpdated);

          // Dispatch a fake 'OrderUpdated' event for testing purposes
          const testEvent = new CustomEvent('OrderUpdated', { detail: { order_id: window.order_id } });
          window.dispatchEvent(testEvent);
        }
      }, 1000); // Adjust the timeout duration as needed
    });

    return {
      purchases,
      loading,
      goBack,
      isModalOpen,
      selectedPurchase,
      openModal,
      orderId,
      calculateTotalAmount,
      calculateGrandTotal,
      generateQrCode,
      parseOptions,
      sidebarOpen,
      toggleSidebar
    };
  }
};
</script>

<style scoped>
.billing-row {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bold-text {
  font-weight: bold;
}

.modal-content {
  max-height: 60vh; /* Adjust height as needed */
  overflow-y: auto;
}

.modal-body {
  position: relative;
}

.modal-body p {
  margin: 0;
  padding-top: 5px;
  font-size: 0.875rem; /* Reduced font size */
}

img.round-thumbnail {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: auto;
}

img.round-thumbnail-modal {
  width: 64px; /* Reduced size */
  height: 64px; /* Reduced size */
  object-fit: cover;
  border-radius: 50%;
}

.amounts-section {
  margin-top: 10px; /* Reduced margin */
  padding: 5px; /* Reduced padding */
  background-color: #f9f9f9;
  border-radius: 10px;
}

.amounts-section p {
  margin: 0;
  padding: 3px 0; /* Reduced padding */
  font-size: 0.875rem; /* Reduced font size */
}

.amounts-section .grand-total {
  font-size: 1rem; /* Reduced font size */
  font-weight: bold;
  color: #2d3748; /* Tailwind's gray-800 */
}

.qr-code {
  margin-top: 10px;
  text-align: center;
}
</style>

