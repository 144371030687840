<template>
  <div class="flex flex-col min-h-screen bg-gray-100">
    <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
    <div class="flex items-center justify-between bg-white shadow p-1">
      <div @click="goBack" class="cursor-pointer">
        <ChevronLeftIcon class="w-6 h-6 text-gray-700" />
      </div>
      <h2 class="main-title text-lg font-semibold text-center tracking-wide uppercase flex-1">Reset Password</h2>
      <SidebarToggle @toggle="toggleSidebar" />
    </div>
    <div class="flex flex-col items-center justify-start flex-grow mt-4 px-4">
      <LoadingSpinner v-if="isLoading" />
      <div v-else class="bg-white shadow-md rounded-lg p-6 w-full">
        <form @submit.prevent="resetPassword">
          <div class="mb-4">
            <label for="newPassword" class="block text-gray-700 text-sm font-bold mb-2">New Password</label>
            <input type="password" id="newPassword" v-model="newPassword" placeholder="Enter your new password" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          </div>
          <div class="mb-4">
            <label for="confirmPassword" class="block text-gray-700 text-sm font-bold mb-2">Confirm Password</label>
            <input type="password" id="confirmPassword" v-model="confirmPassword" placeholder="Confirm your new password" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          </div>
          <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">Reset Password</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import SidebarToggle from '../components/SidebarToggle.vue';
import SidebarComponent from '../components/SidebarComponent.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { supabase } from '../utils/supabase';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import '../assets/custom-alertify.css';

export default {
  name: 'ResetPasswordComponent',
  components: {
    ChevronLeftIcon,
    SidebarToggle,
    SidebarComponent,
    LoadingSpinner
  },
  setup() {
    const newPassword = ref('');
    const confirmPassword = ref('');
    const isLoading = ref(false);
    const sidebarOpen = ref(false);
    const router = useRouter();

    const resetPassword = async () => {
      if (newPassword.value !== confirmPassword.value) {
        alertify.error('Passwords do not match');
        return;
      }

      isLoading.value = true;

      try {
        const { error } = await supabase.auth.updateUser({ 
          password: newPassword.value 
        });

        if (error) throw error;

        alertify.success('Password reset successful');
        router.push('/signin');
      } catch (error) {
        console.error('Error resetting password:', error);
        alertify.error('Failed to reset password');
      } finally {
        isLoading.value = false;
      }
    };

    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    const goBack = () => {
      router.back();
    };

    return {
      newPassword,
      confirmPassword,
      resetPassword,
      goBack,
      isLoading,
      sidebarOpen,
      toggleSidebar
    };
  }
};
</script>


<style scoped>
/* Custom styles if needed */
</style>
