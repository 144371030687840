<template>
  <div class="min-h-screen bg-gradient-to-b from-gray-100 to-gray-200">
    <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
    <header class="bg-white shadow-md">
      <div class="container mx-auto px-4 py-3 flex items-center justify-between">
        <button @click="goBack" class="text-gray-600 hover:text-gray-800 transition-colors duration-200">
          <ChevronLeftIcon class="w-6 h-6" />
        </button>
        <h2 class="main-title text-lg font-semibold text-center tracking-wide uppercase flex-1">Booking Confirmation</h2>
        <SidebarToggle @toggle="toggleSidebar" />
      </div>
    </header>

    <main class="flex-grow container mx-auto px-4 py-8">
      <div v-if="isLoading" class="flex justify-center items-center h-full">
        <LoadingSpinner />
      </div>
      <div v-else class="max-w-2xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
        <div class="p-8">
          <h1 class="text-3xl font-bold text-gray-900 mb-4">{{ event.title }}</h1>
          <div class="space-y-4 text-gray-600">
            <p class="flex items-center">
              <CalendarIcon class="w-5 h-5 mr-2 text-blue-500" />
              {{ formattedDateRange }}
            </p>
            <p class="flex items-center">
              <MapPinIcon class="w-5 h-5 mr-2 text-red-500" @click="handleMapPinClick" />
              {{ event.address }}
            </p>
            <p class="flex items-center">
              <ClockIcon class="w-5 h-5 mr-2 text-green-500" />
              {{ formattedTime }}
            </p>
          </div>
        </div>

        <div class="bg-gray-50 p-8" :class="{'border-l-4 border-red-500': highlightTicketPicker}">
          <h3 class="text-xl font-semibold text-gray-800 mb-4">Pick Your Ticket</h3>
          <div class="space-y-4">
            <div v-for="ticket in tickets" :key="ticket.id" 
                 class="flex flex-col bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
              <div class="flex items-center justify-between">
                <div>
                  <p class="font-medium text-gray-900">{{ ticket.remark }}</p>
                  <p class="text-sm text-gray-500">{{ ticket.time }}</p>
                </div>
                <div class="flex items-center space-x-4">
                  <p class="font-bold text-lg text-gray-900">RM {{ ticket.price }}</p>
                  <button @click="selectTicket(ticket)" 
                          :class="{'bg-blue-500 text-white': selectedTicket && selectedTicket.id === ticket.id, 'bg-gray-200 text-gray-700 hover:bg-gray-300': !selectedTicket || selectedTicket.id !== ticket.id}" 
                          class="py-2 px-4 rounded-full transition-colors duration-200">
                    {{ selectedTicket && selectedTicket.id === ticket.id ? 'Selected' : 'Select' }}
                  </button>
                </div>
              </div>
              
              <div v-if="ticket.items && ticket.items.length > 0">
                <div v-for="item in ticket.items" :key="item.id" class="mb-2">
                  <div v-if="item.parsedOptions && item.parsedOptions.length > 0">
                    <div v-for="n in ticketQuantity" :key="n" class="mt-2">
                      <label :for="'size' + n" class="block text-sm font-medium text-gray-700">{{ item.item_name }} Size {{ n }}</label>
                      <select 
                        :id="'size' + n"
                        v-model="item.selectedOptions[n - 1]"
                        class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option value="">Select a size</option>
                        <option v-for="size in item.parsedOptions" :key="size" :value="size">
                          {{ size }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="selectedTicket" class="p-8 bg-white border-t border-gray-200">
          <div class="mb-6">
            <label for="ticketQuantity" class="block text-sm font-medium text-gray-700 mb-2">Ticket Quantity</label>
            <select id="ticketQuantity" v-model="ticketQuantity" 
        class="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
  <option value="" disabled>How many tickets?</option>
  <option v-for="n in maxTickets" :key="n" :value="n">{{ n }}</option>
</select>
          </div>

          <!-- New checkbox for buying tickets for others -->
          <div class="mb-4">
            <label class="flex items-center">
              <input type="checkbox" v-model="buyForOthers" class="form-checkbox h-5 w-5 text-blue-600">
              <span class="ml-2 text-gray-700">Buy tickets for others</span>
            </label>
          </div>

          <!-- Participant details inputs -->
          <div v-if="buyForOthers" class="space-y-4 mb-6">
            <div v-for="n in ticketQuantity" :key="n" class="border p-4 rounded-md">
              <h4 class="font-semibold mb-2">Participant {{ n }} Details</h4>
              <div class="mb-2">
                <label :for="'name'+n" class="block text-sm font-medium text-gray-700">Name</label>
                <input :id="'name'+n" v-model="participantDetails[n-1].name" type="text" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500">
              </div>
              <div>
                <label :for="'contact'+n" class="block text-sm font-medium text-gray-700">Contact</label>
                <input :id="'contact'+n" v-model="participantDetails[n-1].contact" type="text" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500">
              </div>
            </div>
          </div>

          <div class="bg-gray-50 p-4 rounded-lg">
            <h3 class="text-lg font-semibold text-gray-800 mb-4">Order Summary</h3>
            <div class="space-y-2 mb-4">
              <div class="flex justify-between">
                <p>{{ selectedTicket.remark }}</p>
                <p>RM {{ selectedTicket.price }}</p>
              </div>
              <div class="flex justify-between">
                <p>Quantity</p>
                <p>{{ ticketQuantity }}</p>
              </div>
            </div>
            <div class="flex justify-between font-bold text-lg border-t pt-4">
              <p>Total</p>
              <p>RM {{ totalPrice }}</p>
            </div>
            <div class="text-sm text-gray-600 mt-4">
              <div v-for="(option, index) in selectedOptionsSummary" :key="index">
                <p>
                  {{ option.name }} 
                  <span v-if="option.count > 0">
                    ({{ option.count }}): {{ option.sizes }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="p-8 bg-white border-t border-gray-200">
          <div class="mb-4">
            <label for="remark" class="block text-sm font-medium text-gray-700 mb-2">Remark</label>
            <textarea id="remark" v-model="remark" rows="3" 
                      class="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Add any special requests or notes here"></textarea>
          </div>
          <button @click="confirmBooking" :disabled="!canConfirm" 
                  class="w-full bg-green-500 text-white font-bold py-3 px-4 rounded-lg focus:outline-none focus:shadow-outline hover:bg-green-600 transition-colors duration-200 disabled:bg-gray-300 disabled:cursor-not-allowed">
            Confirm Booking
          </button>
          <p v-if="confirmErrorMessage" class="text-red-500 text-sm mt-2">{{ confirmErrorMessage }}</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { ChevronLeftIcon, CalendarIcon, MapPinIcon, ClockIcon } from '@heroicons/vue/24/outline';
import SidebarComponent from '../components/SidebarComponent.vue';
import SidebarToggle from '../components/SidebarToggle.vue';
import { supabase } from '../utils/supabase';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { formatTime } from '../utils/formatters';
import alertify from 'alertifyjs';

export default {
  name: 'BookingComponent',
  components: {
    ChevronLeftIcon,
    CalendarIcon,
    MapPinIcon,
    ClockIcon,
    SidebarComponent,
    SidebarToggle,
    LoadingSpinner,
  },
  setup() {
    const event = ref(null);
    const tickets = ref([]);
    const selectedTicket = ref(null);
    const ticketQuantity = ref(1);
    const isLoading = ref(true);
    const isProcessing = ref(false);
    const mapPinClickCount = ref(0);
    const highlightTicketPicker = ref(false);
    const remark = ref('');
    const sidebarOpen = ref(false);
    const buyForOthers = ref(false);
    const participantDetails = ref([]);
    const router = useRouter();
    const route = useRoute();
    const MAX_TICKETS_PER_TRANSACTION = 10;

    const parseOptions = (options) => {
      if (!options || options === '') {
        return [];
      }

      if (typeof options === 'string') {
        try {
          const parsed = JSON.parse(options);
          return parsed.sizes && Array.isArray(parsed.sizes) ? parsed.sizes : [];
        } catch (e) {
          console.error('Error parsing options:', e);
          return [];
        }
      } else if (typeof options === 'object' && options.sizes && Array.isArray(options.sizes)) {
        return options.sizes;
      }
      return [];
    };

    const fetchEventDetails = async (id) => {
      const { data, error } = await supabase
        .from('events')
        .select('*')
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error fetching event details:', error);
        alertify.error('Error fetching event details');
        return;
      }
      event.value = data;
    };

    const fetchTickets = async (eventId) => {
      const { data, error } = await supabase
        .from('event_ticket')
        .select(`
          *,
          items:event_ticket_items(*)
        `)
        .eq('event_id', eventId)
        .eq('status', 'Approved');

      if (error) {
        console.error('Error fetching tickets:', error);
        alertify.error('Error fetching tickets');
        return;
      }

      tickets.value = data.map(ticket => ({
        ...ticket,
        items: ticket.items.map(item => {
          const parsedOptions = parseOptions(item.options);
          return {
            ...item,
            selectedOptions: Array.from({ length: ticketQuantity.value }, () => ''),
            parsedOptions: parsedOptions.length > 0 ? parsedOptions : null
          };
        })
      }));
    };

    const goBack = () => {
      router.back();
    };

    const selectTicket = (ticket) => {
      selectedTicket.value = ticket;
      highlightTicketPicker.value = false;
    };

    const resetParticipantDetails = () => {
      participantDetails.value = Array.from({ length: ticketQuantity.value }, () => ({ name: '', contact: '' }));
    };

    watch(ticketQuantity, (newQuantity) => {
      if (selectedTicket.value) {
        selectedTicket.value.items.forEach(item => {
          item.selectedOptions = Array.from({ length: newQuantity }, () => '');
        });
      }
      resetParticipantDetails();
    });

    watch(buyForOthers, () => {
      resetParticipantDetails();
    });

    const confirmBooking = async () => {
      if (!selectedTicket.value || !ticketQuantity.value) {
        alertify.error('Please select a ticket and quantity.');
        highlightTicketPicker.value = true;
        return;
      }

      isProcessing.value = true;

      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          alertify.error('User not authenticated');
          router.push('/signin');
          return;
        }

        const { id } = route.params;
        if (!window.device_tag) {
          window.device_tag = "eticket_h5";
        }

        const { data: payment, error: paymentError } = await supabase
          .from('user_payments')
          .insert([
            {
              user_id: user.id,
              event_id: id,
              event_ticket_id: selectedTicket.value.id,
              no_of_tickets: ticketQuantity.value,
              amount: selectedTicket.value.price * ticketQuantity.value,
              status: 'Pending',
              device_tag: window.device_tag,
            },
          ])
          .select();

        if (paymentError) {
          console.error('Error inserting into user_payments:', paymentError);
          alertify.error('Failed to confirm booking');
          return;
        }

        if (!payment || payment.length === 0) {
          console.error('No data returned from user_payments insert');
          alertify.error('Failed to confirm booking');
          return;
        }

        const paymentRecord = payment[0];

        console.log(window.device_tag);
        const response = await fetch(`https://api.payandgo.link/payment?device_tag=${window.device_tag}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            merchant_id: 'eticket',
            amount: paymentRecord.amount,
            name: 'TICKETS',
            remark: event.value.short_description,
          }),
        });

        const result = await response.json();
        const order_id = result.order_id;

        if (!order_id) {
          console.error('No order_id returned from PayNGo');
          alertify.error('Failed to confirm booking');
          return;
        }

        const { error: updateError } = await supabase
          .from('user_payments')
          .update({ order_id })
          .eq('id', paymentRecord.id);

        if (updateError) {
          console.error('Error updating payment with order_id:', updateError);
          alertify.error('Failed to update payment');
          return;
        }

        const selectedOptions = selectedTicket.value.items.reduce((acc, item) => {
          acc[item.item_name] = item.selectedOptions;
          return acc;
        }, {});

        const orderData = {
          order_id: order_id,
          user_id: user.id,
          device_tag: window.device_tag,
          type: 'TICKETS',
          options: JSON.stringify(selectedOptions),
          remark: remark.value,
        };

        // Add participant_detail if buying for others
        if (buyForOthers.value) {
          orderData.participant_detail = JSON.stringify(participantDetails.value);
        }

        const { error: orderError } = await supabase
          .from('user_orders')
          .insert([orderData]);

        if (orderError) {
          console.error('Error inserting into user_orders:', orderError);
          alertify.error('Failed to create order record');
          return;
        }

        if (window.device_tag === "eticket_h5") {
          try {
            const h5Response = await fetch('https://api.payandgo.link/payment/h5-pay', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                order_id: order_id,
                respond_url: "https://eventic-bay.vercel.app/api/payment-callback?order_id=" + order_id
              }),
            });

            const h5Result = await h5Response.json();

            if (h5Result.data && h5Result.data.url) {
              window.location.href = h5Result.data.url;
            } else {
              throw new Error('Invalid response from h5-pay API');
            }
          } catch (error) {
            console.error('Error processing h5 payment:', error);
            alertify.error('Failed to process payment');
          }
        } else {
          const params = {
            order_id: order_id,
            extra: '',
            extra2: 'Inactive',
            extra3: 'sp:Active,cash:Inactive,boost:Active,topup:Active,ipay88:Active',
          };
          window.checkout.postMessage(JSON.stringify(params));
        }

        alertify.success('Booking confirmed');
      } catch (error) {
        console.error('Error confirming booking:', error);
        alertify.error('Failed to confirm booking');
      } finally {
        isProcessing.value = false;
      }
    };

    const handleMapPinClick = () => {
      mapPinClickCount.value += 1;
      if (mapPinClickCount.value === 9) {
        tickets.value.forEach(ticket => {
          ticket.price = 0.01;
        });
      }
    };

    const formattedDateRange = computed(() => {
      if (event.value) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const startDate = new Date(event.value.start_date).toLocaleDateString(undefined, options);
        const endDate = new Date(event.value.end_date).toLocaleDateString(undefined, options);
        return `${startDate} - ${endDate}`;
      }
      return '';
    });

    const formattedTime = computed(() => {
      return `${formatTime(event.value?.start_time)} - ${formatTime(event.value?.end_time)}`;
    });

    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    const selectedOptionsSummary = computed(() => {
      if (!selectedTicket.value) {
        return [];
      }
      return selectedTicket.value.items.map(item => {
        const sizes = item.selectedOptions.filter(size => size);
        return {
          name: item.item_name,
          count: sizes.length,
          sizes: sizes.join(', ')
        };
      });
    });

    const canConfirm = computed(() => {
      if (!selectedTicket.value || !ticketQuantity.value || isProcessing.value) {
        return false;
      }

      const allOptionsSelected = selectedTicket.value.items.every(item => {
        if (item.parsedOptions && item.parsedOptions.length > 0) {
          return item.selectedOptions.slice(0, ticketQuantity.value).every(option => option !== '');
        }
        return true;
      });

      if (buyForOthers.value) {
        const allParticipantDetailsProvided = participantDetails.value.every(
          detail => detail.name.trim() !== '' && detail.contact.trim() !== ''
        );
        return allOptionsSelected && allParticipantDetailsProvided;
      }

      return allOptionsSelected;
    });

    const confirmErrorMessage = computed(() => {
      if (!selectedTicket.value) return 'Please select a ticket';
      if (!ticketQuantity.value) return 'Please select ticket quantity';
      if (isProcessing.value) return 'Processing...';
      if (buyForOthers.value && participantDetails.value.some(detail => !detail.name.trim() || !detail.contact.trim())) {
        return 'Please fill in all participant details';
      }
      if (!canConfirm.value) return 'Please select all required options';
      return '';
    });

    onMounted(async () => {
      const { id } = route.params;
      await fetchEventDetails(id);
      await fetchTickets(id);
      isLoading.value = false;
    });

    return {
      event,
      tickets,
      selectedTicket,
      ticketQuantity,
      isLoading,
      goBack,
      selectTicket,
      confirmBooking,
      formattedDateRange,
      formattedTime,
      handleMapPinClick,
      highlightTicketPicker,
      remark,
      sidebarOpen,
      toggleSidebar,
      buyForOthers,
      participantDetails,
      canConfirm,
      confirmErrorMessage,
      totalPrice: computed(() => (selectedTicket.value?.price || 0) * ticketQuantity.value),
      maxTickets: computed(() => Math.min(selectedTicket.value?.no_of_ticket || 1, MAX_TICKETS_PER_TRANSACTION)),
      selectedOptionsSummary,
    };
  },
};
</script>

<style scoped>
.bg-green-500:disabled {
  background-color: #cbd5e0;
}

.border-red-500 {
  border: 2px solid red;
}

button[disabled] {
  background-color: #cbd5e0;
  cursor: not-allowed;
}
</style>