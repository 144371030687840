<template>
    <div class="min-h-screen bg-gray-100 flex">
      <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
      <div class="flex flex-col flex-grow">
        <div class="flex items-center justify-between bg-white shadow p-1">
          <div @click="goBack" class="cursor-pointer">
            <ChevronLeftIcon class="w-6 h-6 text-gray-700" />
          </div>
          <h2 class="main-title text-lg font-semibold text-center tracking-wide uppercase flex-1">Get Help</h2>
          <SidebarToggle @toggle="toggleSidebar" />
        </div>
        <div v-if="isLoading" class="flex flex-col items-center justify-center flex-grow">
          <LoadingSpinner />
        </div>
        <div v-else class="p-6 max-w-md w-full mx-auto">
          <div class="bg-white shadow-md rounded-lg p-6">
            <h2 class="text-2xl font-bold mb-4">How can we help you?</h2>
            <div v-if="maxRequestsReached" class="text-red-500 font-semibold mb-4">
              You have submitted the maximum number of requests for today. Please wait for a reply from the admin.
            </div>
            <form v-else @submit.prevent="submitRequest">
              <div class="mb-4">
                <label for="request_content" class="block text-gray-700 text-sm font-bold mb-2">Describe your issue</label>
                <textarea id="request_content" v-model="requestContent" rows="5" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>
              </div>
              <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">Submit</button>
            </form>
            <div class="mt-6">
              <h3 class="text-xl font-bold mb-2">Your Previous Requests</h3>
              <ul>
                <li v-for="request in previousRequests" :key="request.id" class="border-b border-gray-200 py-2">
                  <p class="text-gray-700"><strong>Status:</strong> {{ request.status }}</p>
                  <p class="text-gray-700"><strong>Content:</strong> {{ request.request_content }}</p>
                  <p class="text-gray-700 text-sm"><strong>Submitted:</strong> {{ new Date(request.created_at).toLocaleString() }}</p>
                  <p v-if="request.reply_content" class="text-gray-700"><strong>Reply:</strong> {{ request.reply_content }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import SidebarComponent from '../components/SidebarComponent.vue';
  import SidebarToggle from '../components/SidebarToggle.vue';
  import LoadingSpinner from '../components/LoadingSpinner.vue';
  import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import { supabase } from '../utils/supabase';
  import alertify from 'alertifyjs';
  import 'alertifyjs/build/css/alertify.css';
  
  export default {
    name: 'HelpRequestComponent',
    components: {
      SidebarComponent,
      SidebarToggle,
      LoadingSpinner,
      ChevronLeftIcon
    },
    setup() {
      const requestContent = ref('');
      const isLoading = ref(false);
      const maxRequestsReached = ref(false);
      const previousRequests = ref([]);
      const sidebarOpen = ref(false);
      const router = useRouter();
  
      const checkMaxRequests = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          alertify.error('You must be logged in to submit a help request.');
          return;
        }
  
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const { data, error } = await supabase
          .from('help_requests')
          .select('*')
          .eq('user_id', user.id)
          .eq('status', 'pending')
          .gte('created_at', today.toISOString());
  
        if (error) {
          console.error('Error checking requests:', error);
          alertify.error('Failed to check requests');
          return;
        }
  
        if (data.length >= 3) {
          maxRequestsReached.value = true;
        }
      };
  
      const fetchPreviousRequests = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          alertify.error('You must be logged in to view your requests.');
          return;
        }
  
        const { data, error } = await supabase
          .from('help_requests')
          .select('*')
          .eq('user_id', user.id)
          .order('created_at', { ascending: false });
  
        if (error) {
          console.error('Error fetching requests:', error);
          alertify.error('Failed to fetch requests');
          return;
        }
  
        previousRequests.value = data;
      };
  
      const submitRequest = async () => {
        isLoading.value = true;
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          alertify.error('You must be logged in to submit a help request.');
          isLoading.value = false;
          return;
        }
  
        const { error } = await supabase
          .from('help_requests')
          .insert([{ user_id: user.id, request_content: requestContent.value, status: 'pending' }]);
  
        if (error) {
          alertify.error('Failed to submit request');
        } else {
          alertify.success('Request submitted successfully');
          requestContent.value = '';
          fetchPreviousRequests();
          checkMaxRequests();
        }
        isLoading.value = false;
      };
      const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };
      const goBack = () => {
        router.back();
      };
  
      onMounted(() => {
        fetchPreviousRequests();
        checkMaxRequests();
      });
  
      return {
        requestContent,
        isLoading,
        maxRequestsReached,
        previousRequests,
        submitRequest,
        goBack,
        sidebarOpen,
        toggleSidebar
      };
    }
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>
  