<template>
  <div class="min-h-screen bg-gray-100">
    <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
    <header class="bg-white shadow-md">
      <div class="container mx-auto px-4 py-3 flex items-center justify-between">
        <button @click="goBack" class="text-gray-600 hover:text-gray-800 transition-colors duration-200">
          <ChevronLeftIcon class="w-6 h-6" />
        </button>
        
      <h2 class="main-title text-lg font-semibold text-center tracking-wide uppercase flex-1">Cart Confirmation</h2>
      <SidebarToggle @toggle="toggleSidebar" />
      </div>
    </header>

    <main class="container mx-auto px-4 py-8">
      <div v-if="cartItems.length === 0" class="text-center text-gray-600 py-12">
        <ShoppingCartIcon class="w-16 h-16 mx-auto mb-4 text-gray-400" />
        <p class="text-xl">Your cart is empty.</p>
        <button @click="goBack" class="mt-4 bg-indigo-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-indigo-700 transition-colors duration-300">
          Continue Shopping
        </button>
      </div>
      <div v-else class="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div class="lg:col-span-2 space-y-6">
          <!-- Cart Items -->
          <div v-for="item in cartItems" :key="item.id" class="bg-white rounded-lg shadow-md p-4 flex flex-col sm:flex-row items-start sm:items-center">
            <img :src="item.image_url" :alt="item.name" class="w-full sm:w-24 h-24 object-cover rounded-lg mb-4 sm:mb-0 sm:mr-4">
            <div class="flex-grow">
              <h3 class="text-lg font-semibold text-gray-800">{{ item.name }}</h3>
              <p class="text-sm text-gray-600 mb-2">{{ item.description }}</p>
              <div class="flex items-center space-x-2 mb-2">
                <p class="text-gray-500 line-through" v-if="item.discountpercentage || item.discountvalue">RM {{ item.price }}</p>
                <p class="text-red-500 text-sm font-semibold" v-if="item.discountpercentage">-{{ item.discountpercentage }}%</p>
                <p class="text-red-500 text-sm font-semibold" v-if="item.discountvalue">-RM {{ item.discountvalue }}</p>
                <p class="text-green-600 font-bold">RM {{ calculateDiscountedPrice(item.price, item.discountpercentage, item.discountvalue) }}</p>
              </div>
              <div class="flex items-center space-x-4">
                <div class="flex-1">
                  <label for="quantity" class="block text-sm font-medium text-gray-700 mb-1">Quantity</label>
                  <select v-model="item.selectedQuantity" class="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500">
                    <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                  </select>
                </div>
                <div class="flex-1">
                  <label for="options" class="block text-sm font-medium text-gray-700 mb-1">Options</label>
                  <select v-model="item.selectedOption" class="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500">
                    <option v-for="option in item.parsedOptions" :key="option" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
            </div>
            <button @click="removeFromCart(item)" class="text-red-500 hover:text-red-700 mt-4 sm:mt-0 sm:ml-4 focus:outline-none">
              <TrashIcon class="w-5 h-5" />
            </button>
          </div>
        </div>

        <div class="lg:col-span-1 space-y-6">
          <!-- Delivery Options Card -->
          <div class="bg-white rounded-lg shadow-md p-6">
            <h3 class="text-lg font-semibold text-gray-800 mb-4">Delivery Options</h3>
            <div class="flex items-center mb-4">
              <input type="checkbox" v-model="isSelfCollect" id="selfCollect" class="rounded text-indigo-600 focus:ring-indigo-500 h-4 w-4 mr-2">
              <label for="selfCollect" class="text-sm font-medium text-gray-700">Self Collect</label>
            </div>
            <div v-if="!isSelfCollect" class="space-y-4">
              <div>
                <label for="deliveryaddress" class="block text-sm font-medium text-gray-700 mb-1">Delivery Address</label>
                <input v-model="deliveryAddress" type="text" id="deliveryaddress" class="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" />
              </div>
              <div>
                <label for="contact" class="block text-sm font-medium text-gray-700 mb-1">Contact</label>
                <input v-model="contact" type="text" id="contact" class="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" />
              </div>
              <div class="text-sm text-gray-600">
                <p><strong>Delivery Charge:</strong> RM {{ deliveryCharge }}</p>
                <p class="text-xs text-gray-500 mt-1">*Delivery charge is applied once per order</p>
              </div>
            </div>
          </div>

          <!-- Order Summary Card -->
          <div class="bg-white rounded-lg shadow-md p-6">
            <h3 class="text-lg font-semibold text-gray-800 mb-4">Order Summary</h3>
            <div class="space-y-2 mb-4">
              <div class="flex justify-between">
                <p class="text-gray-600">Subtotal</p>
                <p class="font-semibold">RM {{ totalAmount }}</p>
              </div>
              <div class="flex justify-between">
                <p class="text-gray-600">Delivery Charge</p>
                <p class="font-semibold">RM {{ isSelfCollect ? '0.00' : deliveryCharge }}</p>
              </div>
            </div>
            <div class="flex justify-between pt-4 border-t border-gray-200">
              <p class="text-lg font-semibold text-gray-800">Grand Total</p>
              <p class="text-lg font-semibold text-indigo-600">RM {{ grandTotal }}</p>
            </div>
          </div>

          <button @click="checkout" class="w-full bg-indigo-600 text-white font-semibold py-3 px-4 rounded-md hover:bg-indigo-700 transition-colors duration-300 flex items-center justify-center" :disabled="isProcessing">
            <ShoppingBagIcon class="w-5 h-5 mr-2" />
            {{ isProcessing ? 'Processing...' : 'Checkout' }}
          </button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { ChevronLeftIcon ,TrashIcon } from '@heroicons/vue/24/outline';
import SidebarComponent from '../components/SidebarComponent.vue';
import SidebarToggle from '../components/SidebarToggle.vue';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import '../assets/custom-alertify.css';
import { supabase } from '../utils/supabase';

export default {
  name: 'CartConfirmationComponent',
  components: {
    ChevronLeftIcon,
    TrashIcon,
    SidebarComponent,
    SidebarToggle
  },
  setup() {
    const cartItems = ref([]);
    const isProcessing = ref(false);
    const deliveryAddress = ref('');
    const contact = ref('');
    const isSelfCollect = ref(false);
    const sidebarOpen = ref(false);
    const router = useRouter();

    const fetchCartItems = () => {
      let cart = JSON.parse(localStorage.getItem('cart')) || [];
      cartItems.value = cart.map(item => ({
        ...item,
        parsedOptions: parseOptions(item.options)
      }));
    };

    const fetchProfileData = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;
      
      const { data: profile, error } = await supabase
        .from('profiles')
        .select('address, contact')
        .eq('id', user.id)
        .single();

      if (error) {
        console.error('Error fetching profile data:', error);
        return;
      }

      if (profile) {
        deliveryAddress.value = profile.address || '';
        contact.value = profile.contact || '';
      }
    };

    const parseOptions = (options) => {
      if (typeof options === 'string') {
        try {
          const parsed = JSON.parse(options);
          return parsed.sizes && Array.isArray(parsed.sizes) ? parsed.sizes : [];
        } catch (e) {
          console.error('Error parsing options:', e);
          return [];
        }
      } else if (typeof options === 'object' && options.sizes && Array.isArray(options.sizes)) {
        return options.sizes;
      }
      return [];
    };

    const calculateDiscountedPrice = (price, discountPercentage, discountValue) => {
      let discountedPrice = price;
      if (discountPercentage) {
        discountedPrice -= (price * discountPercentage / 100);
      }
      if (discountValue) {
        discountedPrice -= discountValue;
      }
      return discountedPrice.toFixed(2);
    };

    const deliveryCharge = computed(() => {
      if (isSelfCollect.value) return 0;
      const highestCharge = Math.max(...cartItems.value.map(item => item.deliverycharge));
      return highestCharge;
    });

    const totalAmount = computed(() => {
      return cartItems.value.reduce((total, item) => {
        return total + item.selectedQuantity * calculateDiscountedPrice(item.price, item.discountpercentage, item.discountvalue);
      }, 0).toFixed(2);
    });

    const grandTotal = computed(() => {
      return (parseFloat(totalAmount.value) + (isSelfCollect.value ? 0 : deliveryCharge.value)).toFixed(2);
    });

    const generateUniquePurchaseNo = () => {
      return 'PN' + Date.now();
    };

    const removeFromCart = (itemToRemove) => {
      cartItems.value = cartItems.value.filter(item => 
        !(item.id === itemToRemove.id && item.selectedOption === itemToRemove.selectedOption)
      );
      
      // Update the localStorage with the new cart state
      localStorage.setItem('cart', JSON.stringify(cartItems.value));
      
      alertify.success(`${itemToRemove.name} (${itemToRemove.selectedOption}) removed from cart`);
      
      // If the cart becomes empty after removal, handle that case
      if (cartItems.value.length === 0) {
        alertify.success('Your cart is now empty');
        // Optionally, redirect to another page
        // router.push('/empty-cart');
      }
    };


  

    const checkout = async () => {
      isProcessing.value = true;

      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          alertify.error('User not authenticated');
          router.push('/signin');
          return;
        }

        console.log('Inserting into user_purchases...');

        const purchases = cartItems.value.map(item => ({
          user_id: user.id,
          event_id: item.event_id,
          merchandise_id: item.id,
          quantity: item.selectedQuantity,
          price: item.price,
          discount: item.discountpercentage || item.discountvalue,
          amount: item.selectedQuantity * calculateDiscountedPrice(item.price, item.discountpercentage, item.discountvalue),
          deliverycharge: isSelfCollect.value ? 0 : (item === cartItems.value[0] ? deliveryCharge.value : 0),
          status: 'Pending',
          device_tag: window.device_tag,
          purchase_no: generateUniquePurchaseNo(),
          deliveryaddress: isSelfCollect.value ? '' : deliveryAddress.value,
          contact: isSelfCollect.value ? '' : contact.value,
          selfcollect: isSelfCollect.value,
          options: JSON.stringify({ size: item.selectedOption })
        }));

        const { data, error } = await supabase
          .from('user_purchases')
          .insert(purchases)
          .select();

        if (error) {
          console.error('Error inserting into user_purchases:', error);
          alertify.error('Failed to confirm purchase');
          return;
        }

        console.log('Inserted into user_purchases:', data);

        if (!data || data.length === 0) {
          console.error('No data returned from user_purchases insert');
          alertify.error('Failed to confirm purchase');
          return;
        }

        const totalAmount = parseFloat(grandTotal.value);

        console.log('Making POST request to PayNGo server...');
        const response = await fetch(`https://api.payandgo.link/payment?device_tag=${window.device_tag}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            merchant_id: 'eticket',
            amount: totalAmount,
            name: 'MERCHANDISE',
            remark: 'Purchasing event merchandise'
          }),
        });

        const result = await response.json();
        console.log('PayNGo response:', result);
        const order_id = result.order_id;

        if (!order_id) {
          console.error('No order_id returned from PayNGo');
          alertify.error('Failed to confirm purchase');
          return;
        }

        console.log('Updating user_purchases with order_id...');
        const { error: updateError } = await supabase
          .from('user_purchases')
          .update({ order_id })
          .in('id', data.map(purchase => purchase.id));

        if (updateError) {
          console.error('Error updating purchase with order_id:', updateError);
          alertify.error('Failed to update purchase');
          return;
        }

        console.log('Inserting into user_orders table...');
        const { error: orderError } = await supabase
          .from('user_orders')
          .insert([
            {
              order_id: order_id,
              user_id: user.id,
              device_tag: window.device_tag,
              type: 'MERCHANDISE'
            }
          ]);

        if (orderError) {
          console.error('Error inserting into user_orders:', orderError);
          alertify.error('Failed to insert into user orders');
          return;
        }

        alertify.success('Processing Cart');
        console.log('Sending order_id to PayNGo checkout...');
        const params = {
          order_id: order_id,
          extra: '',
          extra2: 'Inactive',
          extra3: 'sp:Active,cash:Inactive,boost:Active,topup:Active,ipay88:Active',
        };
        window.checkout.postMessage(JSON.stringify(params));
      } catch (error) {
        console.error('Error confirming purchase:', error);
        alertify.error('Failed to confirm purchase');
      } finally {
        isProcessing.value = false;
      }
    };

    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };
    const goBack = () => {
      router.back();
    };

    onMounted(() => {
      fetchCartItems();
      fetchProfileData();
    });

    watch(isSelfCollect, (newValue) => {
      cartItems.value.forEach(item => {
        item.selfCollect = newValue;
      });
    });

    return {
      cartItems,
      deliveryAddress,
      contact,
      isSelfCollect,
      calculateDiscountedPrice,
      deliveryCharge,
      totalAmount,
      grandTotal,
      removeFromCart,
      checkout,
      goBack,
      isProcessing,
      sidebarOpen,
      toggleSidebar
    };
  }
};
</script>

<style scoped>
.iconcart {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #3b82f6;
  color: white;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconcart span {
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  background-color: #ef4444;
  color: white;
  border-radius: 50%;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
}
</style>