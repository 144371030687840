<template>
    <div class="flex flex-col min-h-screen bg-gray-100">
      <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
      <div class="flex items-center justify-between bg-white shadow p-1">
        <div @click="goBack" class="cursor-pointer">
          <ChevronLeftIcon class="w-6 h-6 text-gray-700" />
        </div>
        <h2 class="main-title text-lg font-semibold text-center tracking-wide uppercase flex-1">Profile</h2>
        <SidebarToggle @toggle="toggleSidebar" />
      </div>
      <div class="flex flex-col items-center justify-start flex-grow mt-4">
        <div class="bg-white shadow-md rounded-lg p-6 max-w-sm w-full">
          <form @submit.prevent="updateProfile">
            <div class="mb-4">
              <label for="full_name" class="block text-gray-700 text-sm font-bold mb-2">Full Name</label>
              <input type="text" id="full_name" v-model="profile.full_name" placeholder="Enter your full name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            </div>
            <div class="mb-4">
              <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email</label>
              <input type="email" id="email" v-model="profile.email" readonly placeholder="Enter your email" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100">
            </div>
            <div class="mb-4">
              <label for="contact" class="block text-gray-700 text-sm font-bold mb-2">Contact</label>
              <input type="text" id="contact" v-model="profile.contact" placeholder="Enter your contact" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            </div>
            <div class="mb-4">
              <label for="gender" class="block text-gray-700 text-sm font-bold mb-2">Gender</label>
              <select id="gender" v-model="profile.gender" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                <option value="" disabled>Select gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div class="mb-4">
              <label for="address" class="block text-gray-700 text-sm font-bold mb-2">Address</label>
              <input type="text" id="address" v-model="profile.address" placeholder="Enter your address" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            </div>
            <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">Update</button>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import SidebarToggle from '../components/SidebarToggle.vue';
  import SidebarComponent from '../components/SidebarComponent.vue';
  import { supabase } from '../utils/supabase';
  import alertify from 'alertifyjs';
  
  export default {
    name: 'ProfileComponent',
    components: {
      ChevronLeftIcon,
      SidebarToggle,
      SidebarComponent,
    },
    setup() {
      const profile = ref({
        full_name: '',
        email: '',
        contact: '',
        gender: '',
        address: '',
      });
      const sidebarOpen = ref(false);
      const router = useRouter();
      const isLoading = ref(false);
  
      const fetchProfile = async () => {
        isLoading.value = true;
        const { data: { user }, error: authError } = await supabase.auth.getUser();
  
        if (authError) {
          console.error('Auth error:', authError);
          alertify.error('Failed to authenticate user.');
          isLoading.value = false;
          return;
        }
  
        if (!user) {
          alertify.error('No user logged in');
          isLoading.value = false;
          return;
        }
  
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', user.id)
          .single();
  
        if (error) {
          console.error('Error fetching profile:', error);
          alertify.error('Failed to fetch profile.');
          isLoading.value = false;
          return;
        }
  
        profile.value = data;
        isLoading.value = false;
      };
  
      const updateProfile = async () => {
        isLoading.value = true;
        const { error } = await supabase
          .from('profiles')
          .update(profile.value)
          .eq('id', profile.value.id);
  
        if (error) {
          console.error('Error updating profile:', error);
          alertify.error('Failed to update profile.');
          isLoading.value = false;
          return;
        }
  
        alertify.success('Profile updated successfully.');
        isLoading.value = false;
      };
      const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };
  
      const goBack = () => {
        router.back();
      };
  
      onMounted(() => {
        fetchProfile();
      });
  
      return {
        profile,
        updateProfile,
        goBack,
        isLoading,
        sidebarOpen,
      toggleSidebar
      };
    },
  };
  </script>
  
  <style scoped>
  /* Custom styles if needed */
  </style>
  