<template>
    <div class="min-h-screen bg-gray-100">
      <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
      <div class="flex items-center justify-between bg-white shadow p-1">
        <div @click="goBack" class="cursor-pointer">
          <ChevronLeftIcon class="w-6 h-6 text-gray-700" />
        </div>
        <h2 class="main-title text-lg font-semibold text-center tracking-wide uppercase flex-1">My Tickets</h2>
        <SidebarToggle @toggle="toggleSidebar" />
      </div>
      <div v-if="loading" class="flex justify-center items-center mt-6">
        <LoadingSpinner />
      </div>
      <div v-else class="p-4">
        <div v-for="ticket in tickets" :key="ticket.id" class="ticket-row" @click="openModal(ticket)">
          <div class="flex justify-between items-center">
            <div class="ticket-info">
              <h3 class="text-lg font-bold">{{ ticket.event_title }}</h3>
              <p class="text-red-600"><strong>Ticket No :</strong> {{ ticket.ticket_no }}</p>
              <p><strong>Event Date :</strong> {{ ticket.event_date }}</p>
              <p><strong>Start Time :</strong> {{ ticket.start_time }}</p>
              <p><strong>Purchase Date :</strong> {{ new Date(ticket.purchase_date).toLocaleString() }}</p>
              <p><strong>Status :</strong> {{ ticket.status }}</p>
            </div>
            <div class="ticket-qr">
              <QrCodeIcon class="w-10 h-10 text-gray-500" />
            </div>
          </div>
        </div>
        <ModalComponent :isOpen="isModalOpen" @close="isModalOpen = false">
          <template v-slot:header>
            <h3 class="bold-text">Ticket</h3>
          </template>
          <template v-slot:body>
            <div class="flex justify-center">
              <img :src="qrCodeUrl" alt="QR Code" />
            </div>
            <p class="text-center">{{ selectedTicket.ticket_no }}</p>
          </template>
        </ModalComponent>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { ChevronLeftIcon, QrCodeIcon } from '@heroicons/vue/24/outline';
  import SidebarToggle from '../components/SidebarToggle.vue';
  import SidebarComponent from '../components/SidebarComponent.vue';
  import LoadingSpinner from '../components/LoadingSpinner.vue';
  import ModalComponent from '../components/ModalComponent.vue';
  import { supabase } from '../utils/supabase';
  import alertify from 'alertifyjs';
  import 'alertifyjs/build/css/alertify.min.css';
  import '../assets/custom-alertify.css';
  
  export default {
    name: 'MyTicketsComponent',
    components: {
      ChevronLeftIcon,
      SidebarToggle,
      SidebarComponent,
      LoadingSpinner,
      ModalComponent,
      QrCodeIcon
    },
    setup() {
      const tickets = ref([]);
      const loading = ref(true);
      const sidebarOpen = ref(false);
      const router = useRouter();
      const isModalOpen = ref(false);
      const selectedTicket = ref({});
      const qrCodeUrl = ref('');
  
      const fetchTickets = async () => {
        try {
          const { data: { user } } = await supabase.auth.getUser();
          if (!user) {
            alertify.error('User not authenticated');
            router.push('/signin');
            return;
          }
  
          const { data, error } = await supabase
            .from('user_tickets')
            .select(`
              id,
              ticket_no,
              event_id,
              purchase_date,
              status
            `)
            .eq('participant_id', user.id)
            .order('purchase_date', { ascending: false });
  
          if (error) throw error;
  
          for (let ticket of data) {
            const { data: event, error: eventError } = await supabase
              .from('events')
              .select('title, start_date, start_time')
              .eq('id', ticket.event_id)
              .single();
            
            if (eventError) throw eventError;
  
            ticket.event_title = event.title;
            ticket.event_date = event.start_date;
            ticket.start_time = event.start_time;
          }
  
          tickets.value = data;
        } catch (error) {
          console.error('Error fetching tickets:', error);
          alertify.error('Failed to fetch tickets');
        } finally {
          loading.value = false;
        }
      };
  
      const openModal = (ticket) => {
        selectedTicket.value = ticket;
        qrCodeUrl.value = `https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=${ticket.ticket_no}`;
        isModalOpen.value = true;
      };
      const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };
  
      const goBack = () => {
        router.back();
      };
  
      onMounted(fetchTickets);
  
      return {
        tickets,
        loading,
        goBack,
        isModalOpen,
        selectedTicket,
        qrCodeUrl,
        openModal,
        sidebarOpen,
      toggleSidebar
      };
    }
  };
  </script>
  
  <style scoped>
  .ticket-row {
    position: relative;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .ticket-info {
    flex-grow: 1;
  }
  
  .ticket-qr {
    margin-left: 20px;
  }
  
  .bold-text {
    font-weight: bold;
  }
  
  .modal-body {
    text-align: center;
  }
  
  .modal-body img {
    width: 250px;
    height: 250px;
    margin: auto;
  }
  
  .modal-body p {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  </style>
  