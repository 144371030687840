<template>
  <div class="event-list mt-4">
    <ul>
      <li v-for="event in events" :key="event.id" class="event-item flex items-center mb-4 p-4 border border-gray-200 rounded-lg shadow-sm transition-shadow hover:shadow-lg bg-white hover:bg-gray-50">
        <router-link :to="{ name: 'EventDetail', params: { id: event.id } }" class="event-link flex items-center w-full no-underline text-black">
          <img :src="event.thumbnail1" alt="Event Image" class="event-thumbnail w-24 h-24 rounded-lg object-cover mr-4" />
          <div class="event-details flex flex-col justify-center flex-grow">
            <h3 class="text-base font-semibold mb-1 text-gray-800 wrap">{{ event.title }}</h3>
            <div class="flex items-center text-xs text-gray-600 mb-1">
              <CalendarIcon class="w-3 h-3 mr-1" />
              <span>{{ formatDateRange(event.start_date, event.end_date) }}</span>
            </div>
            <div class="flex items-center text-xs text-gray-600">
              <ClockIcon class="w-3 h-3 mr-1" />
              <span>{{ formatTime(event.start_time) }} - {{ formatTime(event.end_time) }}</span>
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { CalendarIcon, ClockIcon } from '@heroicons/vue/24/outline';
import { supabase } from '../utils/supabase';

export default {
  name: 'EventListComponent',
  components: {
    CalendarIcon,
    ClockIcon
  },
  setup() {
    const events = ref([]);
    const router = useRouter();

    const fetchEvents = async () => {
      try {
        const { data, error } = await supabase
          .from('events')
          .select('*')
          .eq('status', 'Approved')
          .order('start_date', { ascending: true });

        if (error) {
          console.error('Error fetching events:', error);
        } else {
          events.value = data;
        }
      } catch (err) {
        console.error('Error in fetchEvents:', err);
      }
    };

    const formatDateRange = (startDate, endDate) => {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      
      if (start.toDateString() === end.toDateString()) {
        // If start and end dates are the same, return only one date
        return start.toLocaleDateString(undefined, options);
      } else {
        // If dates are different, return the range
        const startStr = start.toLocaleDateString(undefined, options);
        const endStr = end.toLocaleDateString(undefined, options);
        
        // If years are the same, don't repeat the year
        if (start.getFullYear() === end.getFullYear()) {
          const startOptions = { month: 'short', day: 'numeric' };
          return `${start.toLocaleDateString(undefined, startOptions)} - ${endStr}`;
        }
        
        return `${startStr} - ${endStr}`;
      }
    };

    const formatTime = (time) => {
      if (!time) return '';
      const [hours, minutes] = time.split(':');
      return `${hours}:${minutes}`;
    };

    onMounted(() => {
      fetchEvents();
    });

    return {
      events,
      formatDateRange,
      formatTime,
      router
    };
  }
};
</script>

<style scoped>
.event-list {
  margin-top: 1rem;
}

ul {
  list-style: none;
  padding: 0;
}

.event-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  background-color: #ffffff;
}

.event-item:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f7fafc;
}

.event-link {
  display: flex;
  text-decoration: none;
  color: inherit;
  width: 100%;
}

.event-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h3 {
  margin: 0 0 0.25rem;
  font-size: 1rem;
  color: #2d3748;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
</style>