<template>
  <div class="min-h-screen bg-gray-100">
    <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
    <div class="flex items-center justify-between bg-white shadow p-1">
      <div @click="goBack" class="cursor-pointer">
        <ChevronLeftIcon class="w-6 h-6 text-gray-700" />
      </div>
      <h2 class="main-title text-lg font-semibold text-center tracking-wide uppercase flex-1">Sign Up</h2>
      <SidebarToggle @toggle="toggleSidebar" />
    </div>
    <div class="flex flex-col items-center justify-start flex-grow mt-6">
      <div class="bg-white shadow-md rounded-lg p-6 max-w-sm w-full">
        <form @submit.prevent="signUp">
          <div class="mb-4">
            <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Name</label>
            <input type="text" id="name" v-model="name" placeholder="Enter your name" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          </div>
          <div class="mb-4">
            <label for="contact" class="block text-gray-700 text-sm font-bold mb-2">Contact Number</label>
            <input type="tel" id="contact" v-model="contact" placeholder="+6016123456" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          </div>
          <div class="mb-4">
            <label for="country" class="block text-gray-700 text-sm font-bold mb-2">Country</label>
            <select id="country" v-model="country" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
              <option value="Malaysia">Malaysia</option>
              <!-- Add more countries as needed -->
            </select>
          </div>
          <div class="mb-4">
            <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email</label>
            <input type="email" id="email" v-model="email" placeholder="Enter your email" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          </div>
          <div class="mb-4">
            <label for="password" class="block text-gray-700 text-sm font-bold mb-2">Password</label>
            <input type="password" id="password" v-model="password" placeholder="Create a password" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            <p class="text-xs text-gray-500 mt-1">Must be at least 8 characters.</p>
          </div>
          <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">Sign Up</button>
        </form>
        <p class="text-center text-gray-600 text-sm mt-4">
          Already have an account? <router-link to="/signin" class="text-blue-500 hover:text-blue-800">Log in</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import SidebarToggle from '../components/SidebarToggle.vue';
import SidebarComponent from '../components/SidebarComponent.vue';
import { supabase } from '../utils/supabase';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';
import '../assets/custom-alertify.css';

export default {
  name: 'SignUpComponent',
  components: {
    ChevronLeftIcon,
    SidebarToggle,
    SidebarComponent
  },
  setup() {
    const name = ref('');
    const contact = ref('');
    const country = ref('Malaysia');
    const email = ref('');
    const password = ref('');
    const sidebarOpen = ref(false);
    const router = useRouter();

    const signUp = async () => {
  try {
    // Supabase sign-up logic with raw user metadata
    const { data, error } = await supabase.auth.signUp({
      email: email.value,
      password: password.value,
      options: {
        data: {
          Name: name.value,
          Contact: contact.value,
          Country: country.value,
          avatar_url: '' // You can add an avatar URL here if available
        }
      }
    });

    if (error) throw error;

    if (!data.user) {
      throw new Error('User object is not available');
    }

    // On successful sign-up, navigate to the desired route
    alertify.success('Sign-up successful');
    router.push('/');
  } catch (error) {
    console.error('Sign-up error:', error);
    alertify.error('Sign-up failed');
  }
};
const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    const goBack = () => {
      router.back();
    };

    return {
      name,
      contact,
      country,
      email,
      password,
      signUp,
      goBack,
      sidebarOpen,
      toggleSidebar
    };
  }
};
</script>

<style scoped>
/* Custom styles if needed */
</style>
