<template>
  <div class="min-h-screen bg-gray-100">
    <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
    <header class="bg-white shadow-md">
      <div class="container mx-auto px-4 py-3 flex items-center justify-between">
        <button @click="goBack" class="text-gray-600 hover:text-gray-800 transition-colors duration-200">
          <ChevronLeftIcon class="w-6 h-6" />
        </button>
        <h2 v-if="event" class="text-lg font-semibold text-center truncate max-w-[70%]">
          {{ event.title }}
        </h2>
        <SidebarToggle @toggle="toggleSidebar" />
      </div>
    </header>

    <main class="container mx-auto px-4 py-8 space-y-6">
      <div v-if="event" class="relative rounded-xl overflow-hidden shadow-lg">
        <img :src="event.thumbnail2" alt="Event Hero" class="w-full h-64 sm:h-80 object-cover">
        <div class="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black to-transparent text-white">
          <h3 class="text-xl font-bold mb-2">{{ event.title }}</h3>
          <p class="text-sm">{{ event.short_description }}</p>
        </div>
      </div>

      <div v-if="event" class="bg-white rounded-xl shadow-md p-6 space-y-4">
        <div class="flex items-center text-gray-600">
          <CalendarIcon class="w-5 h-5 mr-3 text-blue-500"/>
          <span>{{ formattedDate }}</span>
        </div>
        <div class="flex items-center text-gray-600">
          <MapPinIcon class="w-5 h-5 mr-3 text-red-500"/>
          <span>{{ event.address }}, {{ event.city }}</span>
        </div>
        <div class="flex items-center text-gray-600">
          <ClockIcon class="w-5 h-5 mr-3 text-green-500"/>
          <span>{{ event.start_time }} - {{ event.end_time }}</span>
        </div>
        <div v-if="event.registration_fee" class="flex items-center text-gray-600">
          <CurrencyDollarIcon class="w-5 h-5 mr-3 text-green-500"/>
          <span>Registration Fee: RM {{ event.registration_fee.toFixed(2) }}</span>
        </div>
      </div>

      <form v-if="event" @submit.prevent="handleRegister" class="space-y-6">
        <div v-for="(label, field) in parsedAdditionalInputLabels" :key="field" class="space-y-2">
        <template v-if="label && label !== '-'">
          <label :for="field" class="block text-sm font-medium text-gray-700">{{ label }}</label>
          <textarea
            :id="field"
            v-model="additionalInputs[field]"
            class="mt-1 p-3 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            :placeholder="label"
            :rows="label.length > 40 ? 2 : 1" 
            :class="{ 'h-16': label.length > 40 }"
          ></textarea>
        </template>
      </div>

        <div class="bg-white rounded-xl shadow-md p-6">
          <h4 class="font-bold text-lg mb-3">Terms and Conditions</h4>
          <div v-html="event.instructions" class="text-gray-700 text-sm"></div>
        </div>

        <button type="submit" 
                class="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-300 ease-in-out transform hover:scale-105">
          Confirm Registration
        </button>
      </form>

      <LoadingSpinner v-if="isLoading" />
    </main>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ChevronLeftIcon, CalendarIcon, MapPinIcon, ClockIcon, CurrencyDollarIcon } from '@heroicons/vue/24/outline';
import SidebarToggle from '../components/SidebarToggle.vue';
import SidebarComponent from '../components/SidebarComponent.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { supabase } from '../utils/supabase';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import '../assets/custom-alertify.css';

export default {
  name: 'RegistrationConfirmationComponent',
  components: {
    ChevronLeftIcon,
    CalendarIcon,
    MapPinIcon,
    ClockIcon,
    CurrencyDollarIcon,
    SidebarToggle,
    SidebarComponent,
    LoadingSpinner
  },
  setup() {
    const event = ref(null);
    const route = useRoute();
    const router = useRouter();
    const sidebarOpen = ref(false);
    const isLoading = ref(false);

    const additionalInputs = ref({
      field1: '',
      field2: '',
      field3: '',
      field4: '',
      field5: '',
      field6: ''
    });

    const parsedAdditionalInputLabels = computed(() => {
      if (event.value && event.value.additional_input_label) {
        let labels = event.value.additional_input_label;
        
        if (typeof labels === 'string') {
          try {
            labels = JSON.parse(labels);
          } catch (error) {
            console.error('Error parsing additional_input_label:', error);
            return {};
          }
        }

        return {
          field1: labels.field1_label !== '-' ? labels.field1_label : '',
          field2: labels.field2_label !== '-' ? labels.field2_label : '',
          field3: labels.field3_label !== '-' ? labels.field3_label : '',
          field4: labels.field4_label !== '-' ? labels.field4_label : '',
          field5: labels.field5_label !== '-' ? labels.field5_label : '',
          field6: labels.field6_label !== '-' ? labels.field6_label : ''
        };
      }
      return {};
    });

    const fetchEvent = async () => {
      const { id } = route.params;
      const { data, error } = await supabase
        .from('events')
        .select('*')
        .eq('id', id)
        .single();
      if (error) {
        alertify.error('Error fetching event details');
        return;
      }
      event.value = data;
    };

    const generateRegistrationNo = () => {
      return Math.floor(100000 + Math.random() * 900000).toString();
    };

    const handleRegister = async () => {
      isLoading.value = true;
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          alertify.error('User not authenticated');
          router.push('/signin');
          return;
        }

        const { id } = route.params;
        if (!window.device_tag) {
          window.device_tag = "eticket_h5";
        }

        const registrationNo = generateRegistrationNo();

        const { data: registerData, error: registerError } = await supabase
          .from('event_register')
          .insert([
            {
              participant_id: user.id,
              event_id: id,
              join_date: new Date().toISOString(),
              status: event.value.registration_fee > 0 ? 'Pending' : 'Approved',
              device_tag: window.device_tag,
              registration_no: registrationNo
            }
          ])
          .select()
          .single();

        if (registerError) {
          throw registerError;
        }

        const eventRegisterId = registerData.id;

        // Handle additional inputs
        if (event.value.additional_input) {
          const extendData = {
            event_register_id: eventRegisterId,
            field1: parsedAdditionalInputLabels.value.field1 ? additionalInputs.value.field1 || '' : '',
            field2: parsedAdditionalInputLabels.value.field2 ? additionalInputs.value.field2 || '' : '',
            field3: parsedAdditionalInputLabels.value.field3 ? additionalInputs.value.field3 || '' : '',
            field4: parsedAdditionalInputLabels.value.field4 ? additionalInputs.value.field4 || '' : '',
            field5: parsedAdditionalInputLabels.value.field5 ? additionalInputs.value.field5 || '' : '',
            field6: parsedAdditionalInputLabels.value.field6 ? additionalInputs.value.field6 || '' : '',
            field1_label: parsedAdditionalInputLabels.value.field1 || '',
            field2_label: parsedAdditionalInputLabels.value.field2 || '',
            field3_label: parsedAdditionalInputLabels.value.field3 || '',
            field4_label: parsedAdditionalInputLabels.value.field4 || '',
            field5_label: parsedAdditionalInputLabels.value.field5 || '',
            field6_label: parsedAdditionalInputLabels.value.field6 || '',
          };

          const { error: extendError } = await supabase
            .from('event_register_extend')
            .insert([extendData]);

          if (extendError) {
            throw extendError;
          }
        }

        if (event.value.registration_fee > 0) {
          const { data: payment, error: paymentError } = await supabase
            .from('user_payments')
            .insert([
              {
                user_id: user.id,
                event_id: id,
                amount: event.value.registration_fee,
                status: 'Pending',
                device_tag: window.device_tag,
              }
            ])
            .select();

          if (paymentError) {
            throw paymentError;
          }

          const paymentRecord = payment[0];

          const response = await fetch(`https://api.payandgo.link/payment?device_tag=${window.device_tag}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              merchant_id: 'eticket',
              amount: paymentRecord.amount,
              name: 'EVENT_REGISTRATION',
              remark: event.value.short_description,
            }),
          });

          const result = await response.json();
          const order_id = result.order_id;

          if (!order_id) {
            throw new Error('No order_id returned from PayNGo');
          }

          // Update user_payments with order_id
          await supabase
            .from('user_payments')
            .update({ order_id })
            .eq('id', paymentRecord.id);

          // Update event_register with order_id
          await supabase
            .from('event_register')
            .update({ order_id })
            .eq('id', eventRegisterId);

          await supabase
            .from('user_orders')
            .insert([
              {
                order_id: order_id,
                user_id: user.id,
                device_tag: window.device_tag,
                type: 'EVENT_REGISTRATION',
              }
            ]);

          if (window.device_tag === "eticket_h5") {
            const h5Response = await fetch('https://api.payandgo.link/payment/h5-pay', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                order_id: order_id,
                respond_url: `https://eventic-bay.vercel.app/api/payment-callback?order_id=${order_id}`
              }),
            });

            const h5Result = await h5Response.json();

            if (h5Result.data && h5Result.data.url) {
              window.location.href = h5Result.data.url;
            } else {
              throw new Error('Invalid response from h5-pay API');
            }
          } else {
            const params = {
              order_id: order_id,
              extra: '',
              extra2: 'Inactive',
              extra3: 'sp:Active,cash:Inactive,boost:Active,topup:Active,ipay88:Active',
            };
            window.checkout.postMessage(JSON.stringify(params));
          }

          alertify.success('Please complete the payment process');
        } else {
          alertify.success('Successfully registered for the event');
          router.push('/registered-events');
        }
      } catch (error) {
        console.error('Error during registration:', error);
        alertify.error('Error registering for the event');
      } finally {
        isLoading.value = false;
      }
    };

    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    const goBack = () => {
      router.back();
    };

    const formattedDate = computed(() => {
      if (event.value) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(event.value.start_date).toLocaleDateString(undefined, options);
      }
      return '';
    });

    onMounted(() => {
      fetchEvent();
    });

    return {
      event,
      goBack,
      handleRegister,
      formattedDate,
      sidebarOpen,
      toggleSidebar,
      additionalInputs,
      parsedAdditionalInputLabels,
      isLoading
    };
  }
};
</script>