<template>
    <transition name="modal">
      <div class="modal-mask" v-if="isOpen">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">
                <h3 >Modal Header</h3>
              </slot>
              <button class="modal-close" @click="close">&times;</button>
            </div>
  
            <div class="modal-body">
              <slot name="body">
                Default body content
              </slot>
            </div>
  
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="close">
                  Close
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: 'ModalComponent',
    props: {
      isOpen: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      close() {
        this.$emit('close');
      }
    }
  }
  </script>
  
  <style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-wrapper {
    width: 100%;
    max-width: 600px;
    padding: 20px;
  }
  .modal-container {
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  .modal-header,
  .modal-footer {
    padding: 10px 20px;
    background-color: #f7f7f7;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .modal-body {
    padding: 20px;
  }
  .modal-close {
    background: none;
    border: none;
    font-size: 1.5em;
  }
  </style>
  