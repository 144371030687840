<template>
    <div class="loading-screen fixed inset-0 bg-white flex items-center justify-center">
      <div class="text-center">
        <img src="/eticket.png" alt="Logo" class="mb-4 w-24 h-24 mx-auto"> <!-- Adjust the path to your logo -->
        <img src="/eticket.gif" alt="Logo" class="mb-4 w-24  mx-auto"> 
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoadingScreen'
  };
  </script>
  
  <style scoped>
  .loading-screen {
    z-index: 50; /* Ensure it's on top of other content */
  }
  </style>
  