<template>
  <div class="min-h-screen bg-gray-100">
    <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
    <header class="bg-white shadow-md">
      <div class="container mx-auto px-4 py-3 flex items-center justify-between">
        <button @click="goBack" class="text-gray-600 hover:text-gray-800 transition-colors duration-200">
          <ChevronLeftIcon class="w-6 h-6" />
        </button>
        <h2 v-if="event" class="text-lg font-semibold text-center truncate max-w-[70%]">
          {{ event.title }}
        </h2>
        <SidebarToggle @toggle="toggleSidebar" />
      </div>
    </header>

    <main class="container mx-auto px-4 py-8">
      <div v-if="isLoading" class="flex justify-center items-center h-64">
        <LoadingSpinner />
      </div>
      <div v-else-if="event" class="space-y-6">
        <div class="bg-white rounded-lg shadow-md overflow-hidden">
          <img :src="event.thumbnail2" :alt="event.title" class="w-full h-64 object-cover">
          <div class="p-6">
            <h1 class="text-3xl font-bold mb-4">{{ event.title }}</h1>
            <div class="flex flex-wrap gap-4 mb-6">
              <div class="flex items-center text-gray-700">
                <CalendarIcon class="w-5 h-5 mr-2 -500"/>
                <span>{{ formattedDateRange }}</span>
              </div>
              <div class="flex items-center text-gray-700">
                <MapPinIcon class="w-5 h-5 mr-2 text-red-500"/>
                <span>{{ event.address }}</span>
              </div>
              <div class="flex items-center text-gray-700">
                <ClockIcon class="w-5 h-5 mr-2 text-green-500"/>
                <span>{{ event.start_time }} - {{ event.end_time }}</span>
              </div>
            </div>
            <div class="text-gray-600 mb-6" v-html="event.description"></div>
          </div>
        </div>

        <div class="bg-white rounded-lg shadow-md p-6">
          <h2 class="text-xl font-semibold mb-4">Event Details</h2>
          <p><strong>Organizer:</strong> {{ event.organizer }}</p>
          <p><strong>Category:</strong> {{ event.category }}</p>
          <p v-if="event.sponsored"><strong>Sponsored by:</strong> {{ event.sponsored }}</p>
        </div>

        <div class="bg-white rounded-lg shadow-md p-6">
          <h2 class="text-xl font-semibold mb-4">Instructions</h2>
          <div v-html="event.instructions" class="prose max-w-none"></div>
        </div>

       <!-- New Ticket Items Card -->
       <div v-if="ticketItems.length > 0" class="bg-white rounded-lg shadow-md p-6">
        <h2 class="text-xl font-semibold mb-4">Ticket Included</h2>
        <div class="flex overflow-x-auto space-x-4 pb-4">
          <div v-for="item in ticketItems" :key="item.id" class="flex-shrink-0">
            <div class="flex flex-col items-center w-24">
              <div class="relative w-24 h-24 mb-2">
                <img 
                  :src="item.image1_url || item.image2_url" 
                  :alt="item.item_name" 
                  class="w-full h-full object-cover rounded-lg cursor-pointer"
                  @click="openImageModal(item)"
                >
              </div>
              <span class="text-sm text-center">{{ item.item_name }}</span>
            </div>
          </div>
        </div>
      </div>
        <button @click="handleButtonClick" 
        class="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-300 ease-in-out transform hover:scale-105">
          {{ buttonLabel }}
        </button>
      </div>
      <div v-else class="text-center text-gray-600">
        Event not found.
      </div>
    </main>

    <div v-if="merchandiseImage" @click="goToMerchandise" 
         class="fixed bottom-6 right-4 flex items-center bg-white p-2 rounded-full shadow-lg cursor-pointer transition duration-300 ease-in-out hover:bg-gray-100">
      <div class="w-12 h-12 rounded-full overflow-hidden mr-2">
        <img :src="merchandiseImage" alt="Merchandise Icon" class="w-full h-full object-cover">
      </div>
      <span class="text-gray-700 font-semibold text-sm mr-2">Merchandise</span>
    </div>

    <!-- Image Modal -->
    <div v-if="showImageModal" class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
    <div class="max-w-3xl max-h-full p-4 relative">
      <button @click="closeImageModal" class="absolute top-4 right-4 z-10 bg-black bg-opacity-50 text-white rounded-full p-2 hover:bg-opacity-75 transition-colors duration-200">
        <XMarkIcon class="w-6 h-6" />
      </button>
      <div class="relative">
        <img :src="currentModalImage" alt="Full size image" class="max-w-full max-h-[80vh] object-contain">
        <button 
          v-if="modalImages.length > 1" 
          @click="prevImage" 
          class="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2 hover:bg-opacity-75 transition-colors duration-200"
        >
          <ChevronLeftIcon class="w-6 h-6" />
        </button>
        <button 
          v-if="modalImages.length > 1" 
          @click="nextImage" 
          class="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2 hover:bg-opacity-75 transition-colors duration-200"
        >
        <ChevronRightIcon class="w-6 h-6" />
        </button>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CalendarIcon, MapPinIcon, ClockIcon, ChevronLeftIcon,
    ChevronRightIcon,
    XMarkIcon, } from '@heroicons/vue/24/outline';
import SidebarComponent from './SidebarComponent.vue';
import SidebarToggle from './SidebarToggle.vue';
import LoadingSpinner from './LoadingSpinner.vue';
import { supabase } from '../utils/supabase';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import '../assets/custom-alertify.css';

export default {
  name: 'EventDetailComponent',
  components: {
    CalendarIcon,
    MapPinIcon,
    ClockIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    XMarkIcon,
    SidebarComponent,
    SidebarToggle,
    LoadingSpinner
  },
  setup() {
    const event = ref(null);
    const isLoading = ref(true);
    const route = useRoute();
    const router = useRouter();
    const sidebarOpen = ref(false);
    const buttonLabel = ref('Register Now');
    const merchandiseImage = ref(null);
    const ticketItems = ref([]);
    const modalImages = ref(null);
    const showImageModal = ref(false);
    const currentImageIndex = ref(0);

    const fetchEvent = async () => {
  const { id } = route.params;
  const { data, error } = await supabase
    .from('events')
    .select(`
      *,
      event_ticket (
        event_ticket_items (
          id,
          item_name,
          image1_url,
          image2_url,
          status
        )
      )
    `)
    .eq('id', id)
    .single();

  if (error) {
    alertify.error('Error fetching event details');
    console.error('Error fetching event details:', error);
    isLoading.value = false;
    return;
  }

  event.value = data;
  buttonLabel.value = data.type === 'EVENT' ? 'Register Now' : 'Book Now';
  
  // Filter and flatten ticket items
  ticketItems.value = data.event_ticket.flatMap(ticket => 
    ticket.event_ticket_items.filter(item => item.status === 'Approved')
      .map(({ id, item_name, image1_url, image2_url }) => ({ id, item_name, image1_url, image2_url }))
  );
  
  isLoading.value = false;
};
    const fetchMerchandiseImage = async () => {
      const { id } = route.params;
      const { data, error } = await supabase
        .from('event_merchandise')
        .select('image_url')
        .eq('event_id', id)
        .limit(1)
        .single();

      if (error) {
        console.error('Error fetching merchandise image:', error);
        return;
      }
      if (data) {
        merchandiseImage.value = data.image_url;
      }
    };

    const formattedDateRange = computed(() => {
  if (event.value) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const startDate = new Date(event.value.start_date);
    const endDate = new Date(event.value.end_date);
    
    if (startDate.toDateString() === endDate.toDateString()) {
      // If start and end dates are the same, return only the start date
      return startDate.toLocaleDateString(undefined, options);
    } else {
      // If dates are different, return the range
      return `${startDate.toLocaleDateString(undefined, options)} - ${endDate.toLocaleDateString(undefined, options)}`;
    }
  }
  return '';
});

    const handleButtonClick = async () => {
    console.log(route.params)
      const { id } = route.params;
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        router.push('/signin');
      }else{
        const path = event.value.type === 'EVENT' ? '/registration-confirmation/' : '/booking/';
        router.push(`${path}${id}`);
      }
    };


    const goToMerchandise = () => {
      const { id } = route.params;
      router.push(`/merchandise/${id}`);
    };

    const goBack = () => {
      router.back();
    };

    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    const openImageModal = (item) => {
      modalImages.value = [item.image1_url, item.image2_url].filter(Boolean);
      currentImageIndex.value = 0;
      showImageModal.value = true;
    };

    const closeImageModal = () => {
      showImageModal.value = false;
    };
    const prevImage = () => {
      currentImageIndex.value = (currentImageIndex.value - 1 + modalImages.value.length) % modalImages.value.length;
    };

    const nextImage = () => {
      currentImageIndex.value = (currentImageIndex.value + 1) % modalImages.value.length;
    };

    const currentModalImage = computed(() => modalImages.value[currentImageIndex.value]);

    onMounted(() => {
      fetchEvent();
      fetchMerchandiseImage();
    });

    return {
      event,
      isLoading,
      formattedDateRange,
      handleButtonClick,
      goBack,
      sidebarOpen,
      toggleSidebar,
      buttonLabel,
      merchandiseImage,
      goToMerchandise,
      ticketItems,
      showImageModal,
      modalImages,
      openImageModal,
      closeImageModal,
      prevImage,
      nextImage,
      currentModalImage,
    };
  }
};
</script>

<style scoped>
@media (max-width: 640px) {
  .fixed.bottom-6.right-4 {
    bottom: 1rem;
    right: 1rem;
  }
}
</style>
