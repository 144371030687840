<template>
  <div class="min-h-screen bg-gray-100">
    <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
    <header class="bg-white shadow-md">
      <div class="container mx-auto px-4 py-3 flex items-center justify-between">
        <button @click="goBack" class="text-gray-600 hover:text-gray-800 transition-colors duration-200">
          <ChevronLeftIcon class="w-6 h-6" />
        </button>
        <h2 class="main-title text-lg font-semibold text-center tracking-wide uppercase flex-1">Registered Events</h2>
        <SidebarToggle @toggle="toggleSidebar" />
      </div>
    </header>

    <main class="container mx-auto px-4 py-8">
      <div v-if="loading" class="flex justify-center items-center mt-6">
        <LoadingSpinner />
      </div>
      <div v-else class="space-y-4">
        <div v-for="registration in registrations" :key="registration.id" 
             class="bg-white rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow duration-300 cursor-pointer"
             @click="openModal(registration)">
          <div class="flex items-center justify-between mb-2">
            <h3 class="text-lg font-bold" :class="{'text-green-600': registration.status === 'Approved', 'text-yellow-600': registration.status === 'Pending', 'text-red-600': registration.status === 'Cancelled'}">
              {{ registration.event_title }}
            </h3>
            <div class="status-icon" v-html="getStatusIcon(registration.status)"></div>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
            <p class="text-gray-700"><strong>Registration No:</strong> {{ registration.registration_no }}</p>
            <p class="text-gray-700"><strong>Event Date:</strong> {{ new Date(registration.start_date).toLocaleDateString() }}</p>
            <p class="text-gray-700"><strong>Start Time:</strong> {{ registration.start_time }}</p>
            <p class="text-gray-700"><strong>Status:</strong> {{ registration.status }}</p>
          </div>
        </div>
      </div>

      <ModalComponent :isOpen="isModalOpen" @close="isModalOpen = false">
        <template v-slot:header>
          <div class="flex justify-between items-center">
            <h3 class="text-lg font-bold text-gray-800">Registration Details</h3>
            <button @click="isModalOpen = false" class="text-gray-500 hover:text-gray-700">
              <XMarkIcon class="w-5 h-5" />
            </button>
          </div>
        </template>
        <template v-slot:body>
          <div class="space-y-3">
            <div class="flex justify-between items-center">
              <p class="text-xl font-bold" :class="{'text-green-600': selectedRegistration.status === 'Approved', 'text-yellow-600': selectedRegistration.status === 'Pending', 'text-red-600': selectedRegistration.status === 'Cancelled'}">
                {{ selectedRegistration.status }}
              </p>
              <div class="status-icon-modal" v-html="getStatusIcon(selectedRegistration.status)"></div>
            </div>
            <div class="space-y-2">
              <div>
                <p class="text-xs text-gray-600">Registration No</p>
                <p class="text-sm font-semibold">{{ selectedRegistration.registration_no }}</p>
              </div>
              <div>
                <p class="text-xs text-gray-600">Event Title</p>
                <p class="text-sm font-semibold">{{ selectedRegistration.event_title }}</p>
              </div>
              <div>
                <p class="text-xs text-gray-600">Event Date</p>
                <p class="text-sm font-semibold">{{ new Date(selectedRegistration.start_date).toLocaleDateString() }}</p>
              </div>
              <div>
                <p class="text-xs text-gray-600">Start Time</p>
                <p class="text-sm font-semibold">{{ selectedRegistration.start_time }}</p>
              </div>
            </div>
          </div>
        </template>
      </ModalComponent>
    </main>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ChevronLeftIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import SidebarToggle from '../components/SidebarToggle.vue';
import SidebarComponent from '../components/SidebarComponent.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import ModalComponent from '../components/ModalComponent.vue';
import { supabase } from '../utils/supabase';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';
import '../assets/custom-alertify.css';

export default {
  name: 'RegisteredEventsComponent',
  components: {
    ChevronLeftIcon,
    XMarkIcon,
    SidebarToggle,
    SidebarComponent,
    LoadingSpinner,
    ModalComponent
  },
  setup() {
    const registrations = ref([]);
    const loading = ref(true);
    const router = useRouter();
    const isModalOpen = ref(false);
    const sidebarOpen = ref(false);
    const selectedRegistration = ref({});

    const fetchRegistrations = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          alertify.error('User not authenticated');
          router.push('/signin');
          return;
        }

        const { data, error } = await supabase
          .from('event_register')
          .select(`
            id,          
            status,
            join_date,
            event_id,
            registration_no
          `)
          .eq('participant_id', user.id)
          .order('join_date', { ascending: false });

        if (error) throw error;

        for (let registration of data) {
          const { data: event, error: eventError } = await supabase
            .from('events')
            .select('title, start_date, start_time')
            .eq('id', registration.event_id)
            .single();

          if (eventError) throw eventError;

          registration.event_title = event.title;
          registration.start_date = event.start_date;
          registration.start_time = event.start_time;
        }

        registrations.value = data;
      } catch (error) {
        console.error('Error fetching registrations:', error);
        alertify.error('Failed to fetch registrations');
      } finally {
        loading.value = false;
      }
    };
    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    const openModal = (registration) => {
      selectedRegistration.value = registration;
      isModalOpen.value = true;
    };

    const goBack = () => {
      router.back();
    };

    const getStatusIcon = (status) => {
      switch(status) {
        case 'Approved':
          return `<svg class="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>`;
        case 'Pending':
          return `<svg class="w-6 h-6 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>`;
        case 'Cancelled':
          return `<svg class="w-6 h-6 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>`;
        default:
          return '';
      }
    };

    onMounted(fetchRegistrations);

    return {
      registrations,
      loading,
      goBack,
      isModalOpen,
      sidebarOpen,
      toggleSidebar,
      selectedRegistration,
      openModal,
      getStatusIcon
    };
  }
};
</script>

<style scoped>
.status-icon-modal {
  width: 24px;
  height: 24px;
}
</style>