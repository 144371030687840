<template>
  <div>
    <div class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 transition-opacity"
         :class="{ 'opacity-100': isOpen, 'opacity-0 pointer-events-none': !isOpen }"
         @click="closeSidebar"></div>
    <div class="sidebar fixed inset-y-0 left-0 w-3/4 max-w-xs text-white z-50 transform transition-transform duration-300 ease-in-out"
         :class="{ '-translate-x-full': !isOpen, 'translate-x-0': isOpen }">
      <div class="p-4 flex items-center justify-between bg-opacity-50 bg-gray-900">
        <div>
          <h2 class="text-xl font-bold">Tickets & Events</h2>
          <p class="text-sm">Discover, Book, Experience</p>
        </div>
        <div class="text-2xl cursor-pointer hover:bg-gray-700 p-2 rounded-full transition-colors duration-300"
             @click="closeSidebar">&times;</div>
      </div>
      <div class="sidebar-content p-4 space-y-2">
        <template v-if="isAuthenticated">
          <div class="flex items-center space-x-3 user-email bg-gray-700 p-3 rounded-lg mb-4">
            <UserIcon class="w-6 h-6"/>
            <span class="text-xl font-semibold truncate">{{ userEmail }}</span>
          </div>
        </template>
        <router-link to="/" @click="closeSidebar" class="menu-item">
          <HomeIcon class="w-6 h-6"/>
          <span>Home</span>
        </router-link>
        <template v-if="isAuthenticated">
          <router-link to="/profile" @click="closeSidebar" class="menu-item">
            <UserIcon class="w-6 h-6"/>
            <span>Profile</span>
          </router-link>
          <router-link to="/my-tickets" @click="closeSidebar" class="menu-item">
            <TicketIcon class="w-6 h-6"/>
            <span>Tickets</span>
          </router-link>
          <router-link to="/registered-events" @click="closeSidebar" class="menu-item">
            <CalendarIcon class="w-6 h-6"/>
            <span>Registered Events</span>
          </router-link>
          <router-link to="/billing" @click="closeSidebar" class="menu-item">
            <CreditCardIcon class="w-6 h-6"/>
            <span>Billing</span>
          </router-link>
          <router-link to="/my-purchases" @click="closeSidebar" class="menu-item">
            <ShoppingBagIcon class="w-6 h-6"/>
            <span>Purchases</span>
          </router-link>
          <router-link to="/get-help" @click="closeSidebar" class="menu-item">
            <QuestionMarkCircleIcon class="w-6 h-6"/>
            <span>Get Help</span>
          </router-link>
          <div @click="logout" class="menu-item cursor-pointer">
            <LogoutIcon class="w-6 h-6"/>
            <span>Logout</span>
          </div>
        </template>
        <template v-else>
          <router-link to="/signup" @click="closeSidebar" class="menu-item">
            <UserPlusIcon class="w-6 h-6"/>
            <span>Signup</span>
          </router-link>
          <router-link to="/signin" @click="closeSidebar" class="menu-item">
            <LoginIcon class="w-6 h-6"/>
            <span>Login</span>
          </router-link>
        </template>
      </div>
    </div>
    <LoadingSpinner v-if="isLoading"/>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { supabase } from '../utils/supabase';
import { HomeIcon, UserIcon, TicketIcon, CalendarIcon, CreditCardIcon, ArrowRightOnRectangleIcon as LogoutIcon, UserPlusIcon, ArrowRightOnRectangleIcon as LoginIcon, ShoppingBagIcon, QuestionMarkCircleIcon } from '@heroicons/vue/24/outline';
import LoadingSpinner from './LoadingSpinner.vue';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import '../assets/custom-alertify.css';

export default {
  name: 'SidebarComponent',
  components: {
    HomeIcon,
    UserIcon,
    TicketIcon,
    CalendarIcon,
    CreditCardIcon,
    LogoutIcon,
    UserPlusIcon,
    LoginIcon,
    ShoppingBagIcon,
    QuestionMarkCircleIcon,
    LoadingSpinner
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const isAuthenticated = ref(false);
    const userEmail = ref('');
    const router = useRouter();

    const closeSidebar = () => {
      emit('toggle');
    };

    const logout = async () => {
      isLoading.value = true;
      const { error } = await supabase.auth.signOut();
      isLoading.value = false;
      if (error) {
        alertify.error('Logout failed');
      } else {
        alertify.success('Logout successful');
        isAuthenticated.value = false;
        userEmail.value = '';
        router.push('/signin');
      }
    };

    const checkAuth = async () => {
      const { data: { user: authUser } } = await supabase.auth.getUser();
      isAuthenticated.value = !!authUser;
      if (authUser) {
        userEmail.value = authUser.email;
      }
    };

    onMounted(() => {
      checkAuth();
    });

    return {
      isLoading,
      isAuthenticated,
      userEmail,
      closeSidebar,
      logout
    };
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.sidebar {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(to bottom, #001F3F, #000E13);
}

.menu-item {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 0.85rem;
  transition: background-color 0.3s ease;
  padding: 0.45rem;
  border-radius: 0.25rem;
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-item svg {
  margin-right: 0.75rem;
}

.sidebar-content > * {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.sidebar-content > *:last-child {
  border-bottom: none;
}

.user-email {
  color: #FFD700;
  font-weight: 600;
  font-size: 0.9rem;
}

@media (min-width: 769px) {
  .sidebar {
    display: true;
  }
}
</style>
