<script>
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import LoadingSpinner from './LoadingSpinner.vue';

export default {
  name: 'PaymentStatus',
  components: {
    LoadingSpinner
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      try {
        const orderId = route.query.order_id;
        if (orderId) {
          // Set global variable
          window.goto_param = 'receipt';
          window.order_id = orderId;

          // Redirect to billing
          router.push({ name: 'Billing' });
        } else {
          console.error('No order_id provided');
          router.push({ name: 'Home' });
        }
      } catch (error) {
        console.error('Error in payment status:', error);
        // Handle the error appropriately, maybe redirect to an error page
        router.push({ name: 'ErrorPage' });
      }
    });

    return {};
  }
};
</script>