<template>
  <div v-if="heroEvents.length > 0" class="relative overflow-hidden h-80">
    <div class="absolute inset-0 flex transition-transform ease-in-out duration-500" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
      <div v-for="event in heroEvents" :key="event.id" class="flex-shrink-0 w-full h-full relative">
        <img :src="event.thumbnail2" :alt="event.title" class="w-full h-full object-cover">
        <div class="absolute inset-0 flex flex-col justify-center items-center p-4 bg-black bg-opacity-70 text-white text-center">
          <h3 class="text-xl sm:text-2xl md:text-3xl font-bold mb-2">{{ event.title }}</h3>
          <p class="text-sm sm:text-base md:text-lg mb-4">{{ event.short_description }}</p>
          <router-link :to="{ name: 'EventDetail', params: { id: event.id } }">
            <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Register Now
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
      <button v-for="(event, idx) in heroEvents" :key="idx" @click="setCurrentIndex(idx)" class="w-3 h-3 rounded-full focus:outline-none" :class="currentIndex === idx ? 'bg-white' : 'bg-gray-400'"></button>
    </div>
  </div>
  <LoadingSpinner v-else />
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { supabase } from '../utils/supabase';
import LoadingSpinner from './LoadingSpinner.vue';

export default {
  name: 'HeroComponent',
  components: {
    LoadingSpinner
  },
  setup() {
    const heroEvents = ref([]);
    const currentIndex = ref(0);
    let intervalId = null;

    const fetchHeroEvents = async () => {
      const { data, error } = await supabase
        .from('events')
        .select('*')
        .eq('hero', true)
        .order('created_at', { ascending: false })
        .limit(5);  // Limit to 5 hero events, adjust as needed

      if (error) {
        console.error('Error fetching hero events:', error);
        return;
      }
      heroEvents.value = data;
    };

    const setCurrentIndex = (index) => {
      currentIndex.value = index;
    };

    const nextSlide = () => {
      currentIndex.value = (currentIndex.value + 1) % heroEvents.value.length;
    };

    onMounted(() => {
      fetchHeroEvents();
      intervalId = setInterval(nextSlide, 5000); // Change slide every 5 seconds
    });

    onUnmounted(() => {
      if (intervalId) clearInterval(intervalId);
    });

    return {
      heroEvents,
      currentIndex,
      setCurrentIndex
    };
  }
};
</script>

<style scoped>
/* Custom styles for the hero section */
.hero-section {
  position: relative;
  height: 20rem; /* Adjust the height as needed */
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.hero-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7); /* Increased opacity for darker overlay */
  color: white;
  text-align: center;
}

.hero-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.hero-description {
  margin-top: 0.5rem;
}

.hero-button {
  margin-top: 1rem;
  background-color: #e3342f;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s;
}

.hero-button:hover {
  background-color: #cc1f1a;
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
}

.relative {
  position: relative;
}
</style>
