<template>
  <div class="min-h-screen bg-gray-100">
    <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
    <header class="bg-white shadow-md">
      <div class="container mx-auto px-4 py-3 flex items-center justify-between">
        <button @click="goBack" class="text-gray-600 hover:text-gray-800 transition-colors duration-200">
          <ChevronLeftIcon class="w-6 h-6" />
        </button>
        <h2 class="main-title text-lg font-semibold text-center tracking-wide uppercase flex-1">Merchandise</h2>
        <SidebarToggle @toggle="toggleSidebar" />
      </div>
    </header>

    <main class="container mx-auto px-4 py-8">
      <div v-if="merchandise.length === 0" class="text-center text-gray-600 py-12">
        <ShoppingBagIcon class="w-16 h-16 mx-auto mb-4 text-gray-400" />
        <p class="text-xl">No merchandise available for this event.</p>
      </div>
      <div v-else class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <div v-for="item in merchandise" :key="item.id" class="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:shadow-lg hover:-translate-y-1">
          <img :src="item.image_url" :alt="item.name" class="w-full h-64 object-cover">
          <div class="p-6">
            <h3 class="text-xl font-semibold text-gray-800 mb-2">{{ item.name }}</h3>
            <p class="text-gray-600 mb-4">{{ item.description }}</p>
            <div class="flex items-center space-x-2 mb-4">
              <p class="text-gray-900 font-bold line-through" v-if="item.discountpercentage || item.discountvalue">RM {{ item.price }}</p>
              <p class="text-red-500 font-bold text-sm" v-if="item.discountpercentage">-{{ item.discountpercentage }}%</p>
              <p class="text-red-500 font-bold text-sm" v-if="item.discountvalue">-RM {{ item.discountvalue }}</p>
              <p class="text-green-600 font-bold text-xl">RM {{ calculateDiscountedPrice(item.price, item.discountpercentage, item.discountvalue) }}</p>
            </div>
            <div class="space-y-4">
              <div>
                <label for="quantity" class="block text-sm font-medium text-gray-700 mb-1">Quantity</label>
                <select v-model="item.selectedQuantity" class="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500">
                  <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                </select>
              </div>
              <div>
                <label for="options" class="block text-sm font-medium text-gray-700 mb-1">Options</label>
                <select v-model="item.selectedOption" class="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500">
                  <option value="" disabled>Select an option</option>
                  <option v-for="option in item.parsedOptions" :key="option" :value="option">{{ option }}</option>
                </select>
              </div>
              <div class="flex items-center">
                <input type="checkbox" v-model="item.selfCollect" id="selfCollect" class="rounded text-indigo-600 focus:ring-indigo-500 h-4 w-4 mr-2">
                <label for="selfCollect" class="text-sm font-medium text-gray-700">Self Collect</label>
              </div>
              <div v-if="!item.selfCollect" class="text-sm text-gray-600">
                <p><strong>Delivery Charge:</strong> RM {{ calculateDeliveryCharge(item) }}</p>
                <p class="text-xs text-gray-500 mt-1">{{ item.note_freedelivery }}</p>
              </div>
              <div v-if="item.selfCollect" class="text-xs text-gray-600 mt-2">
                <p>{{ item.note_selfcollect }}</p>
              </div>
            </div>
            <button @click="addToCart(item)" class="mt-6 w-full bg-indigo-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-indigo-700 transition-colors duration-300 flex items-center justify-center">
              <ShoppingCartIcon class="w-5 h-5 mr-2" />
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </main>

    <button @click="goToCart" class="fixed bottom-6 right-6 bg-indigo-600 text-white p-4 rounded-full shadow-lg hover:bg-indigo-700 transition-colors duration-300">
      <ShoppingCartIcon class="w-6 h-6" />
      <span v-if="cartCount > 0" class="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full w-6 h-6 flex items-center justify-center">
        {{ cartCount }}
      </span>
    </button>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ChevronLeftIcon, ShoppingCartIcon, ShoppingBagIcon } from '@heroicons/vue/24/outline';
import SidebarComponent from '../components/SidebarComponent.vue';
import SidebarToggle from '../components/SidebarToggle.vue';
import { supabase } from '../utils/supabase';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import '../assets/custom-alertify.css';

export default {
  name: 'MerchandiseComponent',
  components: {
    ChevronLeftIcon,
    ShoppingCartIcon,
    ShoppingBagIcon,
    SidebarComponent,
    SidebarToggle
  },
  setup() {
    const merchandise = ref([]);
    const cartCount = ref(0);
    const route = useRoute();
    const sidebarOpen = ref(false);
    const router = useRouter();

    const fetchMerchandise = async () => {
      const { id } = route.params;
      const { data, error } = await supabase
        .from('event_merchandise')
        .select('*')
        .eq('event_id', id);

      if (error) {
        alertify.error('Error fetching merchandise items');
        return;
      }

      merchandise.value = data.map(item => ({
        ...item,
        selectedQuantity: 1,
        selfCollect: false,
        selectedOption: '',
        parsedOptions: parseOptions(item.options)
      }));
    };

    const parseOptions = (options) => {
      if (typeof options === 'string') {
        try {
          const parsed = JSON.parse(options);
          return parsed.sizes && Array.isArray(parsed.sizes) ? parsed.sizes : [];
        } catch (e) {
          console.error('Error parsing options:', e);
          return [];
        }
      } else if (typeof options === 'object' && options.sizes && Array.isArray(options.sizes)) {
        return options.sizes;
      }
      return [];
    };

    const addToCart = (item) => {
      if (!item.selectedOption) {
        alertify.error('Please select an option before adding to cart');
        return;
      }
      alertify.success(`Added ${item.name} to cart`);
      cartCount.value += 1;
      let cart = JSON.parse(localStorage.getItem('cart')) || [];
      cart.push(item);
      localStorage.setItem('cart', JSON.stringify(cart));
    };

    const calculateDiscountedPrice = (price, discountPercentage, discountValue) => {
      let discountedPrice = price;
      if (discountPercentage) {
        discountedPrice -= (price * discountPercentage / 100);
      }
      if (discountValue) {
        discountedPrice -= discountValue;
      }
      return discountedPrice.toFixed(2);
    };

    const calculateDeliveryCharge = (item) => {
      if (item.selfCollect) {
        return 0;
      }
      return item.selectedQuantity >= item.freedeliveryqty ? 0 : item.deliverycharge;
    };

    const goToCart = () => {
      router.push('/cart-confirmation');
    };
    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    const goBack = () => {
      router.back();
    };

    onMounted(() => {
      fetchMerchandise();
      let cart = JSON.parse(localStorage.getItem('cart')) || [];
      cartCount.value = cart.length;
    });

    return {
      merchandise,
      cartCount,
      addToCart,
      calculateDiscountedPrice,
      calculateDeliveryCharge,
      goToCart,
      goBack,
      sidebarOpen,
      toggleSidebar
    };
  }
};
</script>

<style scoped>
.iconcart {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #3b82f6;
  color: white;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconcart span {
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  background-color: #ef4444;
  color: white;
  border-radius: 50%;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
}
</style>