<template>
  <div class="min-h-screen bg-gray-100">
    <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
    <header class="bg-white shadow-md">
      <div class="container mx-auto px-2 py-2 flex items-center justify-between">
        <button @click="goBack" class="text-gray-600 hover:text-gray-800 transition-colors duration-200">
          <ChevronLeftIcon class="w-6 h-6" />
        </button>
        <h2 class="main-title text-lg font-semibold text-center tracking-wide uppercase flex-1">Billing</h2>
        <SidebarToggle @toggle="toggleSidebar" />
      </div>
    </header>

    <main class="container mx-auto px-2 py-4">
      <div v-if="loading" class="flex justify-center items-center mt-4">
        <LoadingSpinner />
      </div>
      <div v-else class="space-y-2">
        <div v-for="payment in payments" :key="payment.id" 
             class="bg-white rounded-lg shadow-md p-3 hover:shadow-lg transition-shadow duration-300 cursor-pointer"
             @click="openModal(payment)">
          <div class="flex items-center justify-between mb-1">
            <h3 class="text-base font-bold" :class="{'text-green-600': payment.status === 'Paid', 'text-red-600': payment.status !== 'Paid'}">
              Status: {{ payment.status }}
            </h3>
            <span class="text-xs text-gray-500">{{ new Date(payment.created_at).toLocaleString() }}</span>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-1 text-sm">
            <p class="text-gray-700"><strong>Payment No:</strong> {{ payment.payment_no }}</p>
            <p class="text-gray-700"><strong>Event:</strong> {{ payment.event_title }}</p>
            <p class="text-gray-700"><strong>Order ID:</strong> {{ payment.order_id || 'N/A' }}</p>
            <p class="text-gray-700"><strong>Gateway:</strong> {{ payment.payment_gateway_no || 'N/A' }}</p>
          </div>
        </div>
      </div>

      <ModalComponent :isOpen="isModalOpen" @close="isModalOpen = false">
        <template v-slot:header>
          <div class="flex justify-between items-center">
            <h3 class="text-lg font-bold text-gray-800">Receipt</h3>
          </div>
        </template>
        <template v-slot:body>
          <div class="space-y-2 max-h-[80vh] overflow-y-auto">
            <div class="flex justify-between items-center">
              <p class="text-xl font-bold" :class="{'text-green-600': selectedPayment.status === 'Paid', 'text-red-600': selectedPayment.status !== 'Paid'}">
                {{ selectedPayment.status }}
              </p>
              <p class="text-sm text-gray-500">{{ new Date(selectedPayment.created_at).toLocaleString() }}</p>
            </div>
            <div class="grid grid-cols-2 gap-2">
              <div class="space-y-1">
                <div>
                  <p class="text-xs text-gray-600">Order ID</p>
                  <p class="text-sm font-semibold">{{ selectedPayment.order_id || 'N/A' }}</p>
                </div>
                <div>
                  <p class="text-xs text-gray-600">Payment No</p>
                  <p class="text-sm font-semibold">{{ selectedPayment.payment_no }}</p>
                </div>
                <div>
                  <p class="text-xs text-gray-600">Event</p>
                  <p class="text-sm font-semibold">{{ selectedPayment.event_title }}</p>
                </div>
                <div v-if="selectedPayment.orderType !== 'EVENT_REGISTRATION'">
                  <p class="text-xs text-gray-600">Tickets</p>
                  <p class="text-sm font-semibold">{{ selectedPayment.no_of_tickets }}</p>
                </div>
              </div>
              <div class="flex justify-end">
                <img :src="qrCodeUrl" alt="QR Code" class="w-24 h-24" />
              </div>
            </div>
            <div v-if="selectedPayment.options || selectedPayment.participant_detail" class="grid grid-cols-2 gap-2 mt-4">
              <div v-if="selectedPayment.options && Object.keys(selectedPayment.options).length > 0">
                <p class="text-xs text-gray-600 font-semibold mb-1">Options</p>
                <div v-for="(value, key) in selectedPayment.options" :key="key" class="text-sm">
                  <span v-if="Array.isArray(value) && value.length > 0">
                    {{ key }}: {{ value.filter(v => v !== '').join(', ') || `x${value.length}` }}
                  </span>
                </div>
              </div>
              <div v-if="selectedPayment.participant_detail && selectedPayment.participant_detail.length > 0">
                <p class="text-xs text-gray-600 font-semibold mb-1">Participant Details</p>
                <div v-for="(participant, index) in selectedPayment.participant_detail" :key="index" class="text-sm">
                  <p>{{ participant.name }} ({{ participant.contact }})</p>
                </div>
              </div>
            </div>
            <div class="border-t pt-2 mt-2">
              <p class="text-lg font-bold text-gray-800">Total Amount: <span class="text-green-600">RM {{ selectedPayment.amount }}</span></p>
            </div>
            <button v-if="showGotoButton" @click="gotoDetails" 
                    class="w-full bg-indigo-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-indigo-700 transition-colors duration-300 mt-2">
              {{ selectedPayment.orderType === 'EVENT_REGISTRATION' ? 'Go to Registered Events' : 'Go to My Tickets' }}
            </button>
          </div>
        </template>
      </ModalComponent>
    </main>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import SidebarToggle from '../components/SidebarToggle.vue';
import SidebarComponent from '../components/SidebarComponent.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import ModalComponent from '../components/ModalComponent.vue';
import { supabase } from '../utils/supabase';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';
import '../assets/custom-alertify.css';

export default {
  name: 'BillingComponent',
  components: {
    ChevronLeftIcon,
    SidebarToggle,
    SidebarComponent,
    LoadingSpinner,
    ModalComponent
  },
  setup() {
    const payments = ref([]);
    const loading = ref(true);
    const router = useRouter();
    const isModalOpen = ref(false);
    const selectedPayment = ref({});
    const orderId = ref(null);
    const showGotoButton = ref(false);
    const sidebarOpen = ref(false);

    const qrCodeUrl = computed(() => {
      if (selectedPayment.value && selectedPayment.value.payment_no) {
        return `https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=${selectedPayment.value.payment_no}`;
      }
      return '';
    });

    const fetchPayments = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          alertify.error('User not authenticated');
          router.push('/signin');
          return;
        }

        const { data, error } = await supabase
          .from('user_payments')
          .select(`
            id,
            created_at,
            payment_no,
            amount,
            status,
            order_id,
            payment_gateway_no,
            event_ticket_id,
            event_id,
            no_of_tickets
          `)
          .eq('user_id', user.id)
          .order('created_at', { ascending: false });

        if (error) throw error;

        for (let payment of data) {
          const { data: event, error: eventError } = await supabase
            .from('events')
            .select('title')
            .eq('id', payment.event_id)
            .single();

          if (eventError) throw eventError;

          payment.event_title = event.title;

          // Fetch options and type from user_orders
          const { data: orderData, error: orderError } = await supabase
            .from('user_orders')
            .select('options, type, participant_detail')
            .eq('order_id', payment.order_id)
            .single();

          if (orderError) {
            console.error('Error fetching order data:', orderError);
          } else if (orderData) {
            payment.orderType = orderData.type;
            if (orderData.options) {
              try {
                payment.options = JSON.parse(orderData.options);
              } catch (e) {
                console.error('Error parsing options JSON:', e);
                payment.options = {};
              }
            } else {
              payment.options = {};
            }
            if (orderData.participant_detail) {
              try {
                payment.participant_detail = JSON.parse(orderData.participant_detail);
              } catch (e) {
                console.error('Error parsing participant_detail JSON:', e);
                payment.participant_detail = [];
              }
            } else {
              payment.participant_detail = [];
            }
          }
        }

        payments.value = data;
      } catch (error) {
        console.error('Error fetching payments:', error);
        alertify.error('Failed to fetch payments');
      } finally {
        loading.value = false;
      }
    };

    const openModal = (payment) => {
      selectedPayment.value = payment;
      isModalOpen.value = true;
      showGotoButton.value = true;
    };

    const fetchPaymentByOrderId = async (orderId) => {
      try {
        const { data, error } = await supabase
          .from('user_payments')
          .select(`
            id,
            created_at,
            payment_no,
            amount,
            status,
            order_id,
            payment_gateway_no,
            event_ticket_id,
            event_id,
            no_of_tickets
          `)
          .eq('order_id', orderId)
          .single();

        if (error) throw error;

        if (!data) {
          console.error('No data returned from user_payments');
          alertify.error('Failed to fetch payment details');
          return;
        }

        const { data: event, error: eventError } = await supabase
          .from('events')
          .select('title')
          .eq('id', data.event_id)
          .single();

        if (eventError) throw eventError;

        data.event_title = event.title;

        // Fetch options and type from user_orders
        const { data: orderData, error: orderError } = await supabase
          .from('user_orders')
          .select('options, type, participant_detail')
          .eq('order_id', data.order_id)
          .single();

        if (orderError) {
          console.error('Error fetching order data:', orderError);
        } else if (orderData) {
          data.orderType = orderData.type;
          if (orderData.options) {
            try {
              data.options = JSON.parse(orderData.options);
            } catch (e) {
              console.error('Error parsing options JSON:', e);
              data.options = {};
            }
          } else {
            data.options = {};
          }
          if (orderData.participant_detail) {
            try {
              data.participant_detail = JSON.parse(orderData.participant_detail);
            } catch (e) {
              console.error('Error parsing participant_detail JSON:', e);
              data.participant_detail = [];
            }
          } else {
            data.participant_detail = [];
          }
        }

        selectedPayment.value = data;
        isModalOpen.value = true;
        showGotoButton.value = true;

        // Reset goto_param and order_id after showing the modal
        window.goto_param = '';
        window.order_id = '';
      } catch (error) {
        console.error('Error fetching payment by order ID:', error);
        alertify.error('Failed to fetch payment details');
      }
    };

    const handleOrderUpdated = (event) => {
      const { order_id } = event.detail;
      fetchPaymentByOrderId(order_id);
    };

    const goBack = () => {
      router.back();
    };

    const gotoDetails = () => {
      if (selectedPayment.value.orderType === 'EVENT_REGISTRATION') {
        router.push('/registered-events');
      } else {
        router.push('/my-tickets');
      }
    };

    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    onMounted(() => {
      fetchPayments();

      // Check if goto_param is 'receipt' and listen for the 'OrderUpdated' event
      setTimeout(() => {
        if (window.goto_param === 'receipt') {
          window.addEventListener('OrderUpdated', handleOrderUpdated);

          // Dispatch a fake 'OrderUpdated' event for testing purposes
          const testEvent = new CustomEvent('OrderUpdated', { detail: { order_id: window.order_id } });
          window.dispatchEvent(testEvent);
        }
      }, 1000); // Adjust the timeout duration as needed
    });

    return {
      payments,
      loading,
      goBack,
      isModalOpen,
      selectedPayment,
      openModal,
      orderId,
      showGotoButton,
      gotoDetails,
      sidebarOpen,
      toggleSidebar,
      qrCodeUrl
    };
  }
};
</script>

<style scoped>
.billing-row {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.status-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.status-icon-modal {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.status-icon-svg {
  width: 64px;
  height: 64px;
}

.status-icon-svg-modal {
  width: 80px;
  height: 80px;
}

.bold-text {
  font-weight: bold;
}

.modal-body {
  position: relative;
}

.modal-body p {
  margin: 0;
  padding-top: 5px;
  font-size: 0.875rem; /* Reduced font size */
}

/* Styles for the QR code image */
img.qr-code {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .grid-cols-2 {
    grid-template-columns: 1fr; /* Stack columns on small screens */
  }

  .status-icon-modal {
    position: static;
    margin: 10px auto;
  }

  img.qr-code {
    width: 80px;
    height: 80px;
  }
}

/* Custom scrollbar for webkit browsers */
.custom-scrollbar {
  max-height: 80vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4a5568 #cbd5e0;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #cbd5e0;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 4px;
  border: 2px solid #cbd5e0;
}
</style>