<template>
    <div class="flex flex-col min-h-screen bg-gray-100">
      <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
      <div class="flex items-center justify-between bg-white shadow p-1">
        <div @click="goBack" class="cursor-pointer">
          <ChevronLeftIcon class="w-6 h-6 text-gray-700" />
        </div>
        <h2 class="main-title text-lg font-semibold text-center tracking-wide uppercase flex-1">Forgot Password</h2>
        <SidebarToggle @toggle="toggleSidebar" />
      </div>
      <div class="flex flex-col items-center justify-start flex-grow mt-4 px-4">
        <LoadingSpinner v-if="isLoading" />
        <div v-else class="bg-white shadow-md rounded-lg p-6 w-full">
          <form @submit.prevent="resetPassword">
            <div class="mb-4">
              <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email</label>
              <input type="email" id="email" v-model="email" placeholder="Enter your email" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            </div>
            <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">Reset Password</button>
          </form>
          <p class="text-center text-gray-600 text-sm mt-4">
            Remembered your password? <router-link to="/signin" class="text-blue-500 hover:text-blue-800">Sign in</router-link>
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import SidebarToggle from '../components/SidebarToggle.vue';
  import SidebarComponent from '../components/SidebarComponent.vue';
  import LoadingSpinner from '../components/LoadingSpinner.vue';
  import { supabase } from '../utils/supabase';
  import alertify from 'alertifyjs';
  import 'alertifyjs/build/css/alertify.css';
  import '../assets/custom-alertify.css';
  
  export default {
    name: 'ForgotPasswordComponent',
    components: {
      ChevronLeftIcon,
      SidebarToggle,
      SidebarComponent,
      LoadingSpinner
    },
    setup() {
      const email = ref('');
      const isLoading = ref(false);
      const sidebarOpen = ref(false);
      const router = useRouter();
  
      const resetPassword = async () => {
        isLoading.value = true;
        const { error } = await supabase.auth.resetPasswordForEmail(email.value,{redirectTo:'https://eventic.events/reset-password'});
        isLoading.value = false;
        if (error) {
          alertify.error('Failed to send reset password email');
        } else {
          alertify.success('Reset password email sent');
          router.push('/signin');
        }
      };
      const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };
      const goBack = () => {
        router.back();
      };
  
      return {
        email,
        resetPassword,
        goBack,
        isLoading,
        sidebarOpen,
        toggleSidebar
      };
    }
  };
  </script>
  
  <style scoped>
  /* Custom styles if needed */
  </style>
  